import { Tooltip, Typography } from '@mui/material';
import React from 'react';

import { darkGrey } from '@/src/styles/theme/lightThemeOptions';

export const TextOnlyTooltip = ({
    children,
    title,
    keyData = title,
    placement = 'bottom',
}: {
    children: React.ReactElement;
    title: string;
    keyData?: string | number;
    placement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
        | undefined;
}) => (
    <Tooltip
        title={<Typography variant="body2">{title}</Typography>}
        arrow
        key={keyData}
        placement={placement}
        PopperProps={{
            sx: {
                '& .MuiTooltip-tooltip': {
                    backgroundColor: darkGrey,
                    padding: '0.313rem 0.563rem',
                    maxWidth: '15.25rem',
                    minWidth: '1.75rem',
                },
                '& .MuiTooltip-arrow': {
                    color: darkGrey,
                    left: '-4px !important',
                },
            },
        }}
    >
        {children}
    </Tooltip>
);
