'use client';

import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { GingerToolbar } from '../components/layouts/style';
import ChatBox from '../modules/Ginger/Asks/components/ResponsiveChatBox';
import { Cares } from '../modules/Ginger/Cares';
import { defaultlayout } from '../shared/constants/layoutConstant';
import { smallScreenTab } from '../shared/constants/MediaQueryConstants';
import { withMsalAuthentication } from '../shared/hocs/withMsalAuthentication';
import useErrorModalHooks from '../shared/hooks/useErrorModalHooks';
import { getFeeds } from '../shared/services/apiServices/feedApi';
import type { FeedApiResponse } from '../shared/services/apiServices/responseModel/feedsType';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
    layout?: string;
};
const Asks = dynamic(() => import('../modules/Ginger/Asks'), { ssr: false });

const Home: NextPageWithLayout = withMsalAuthentication(() => {
    const [userFeeds, setUserFeeds] = useState<FeedApiResponse[]>([]);
    const matchesScreenSize = useMediaQuery(smallScreenTab);
    const { openDialog, setResponseErrorContent, dialogComponent } = useErrorModalHooks();
    const getUserFeeds = async () => {
        const getUserfeedData: any = await getFeeds();
        if (getUserfeedData?.error || getUserfeedData?.errors) {
            setResponseErrorContent(getUserfeedData);
            openDialog();
        } else {
            setUserFeeds(getUserfeedData?.data);
        }
    };
    const [askDisplay, setAskDisplay] = useState<boolean>(false);
    const handleClick = () => {
        setAskDisplay(true);
    };
    useEffect(() => {
        getUserFeeds();
    }, []);

    return (
        <>
            <GingerToolbar container spacing={12} className="home_page">
                {!askDisplay ? (
                    <Grid item xs={12} md={12} lg={7} className="grid-care">
                        <Cares userFeeds={userFeeds} />
                    </Grid>
                ) : (
                    ''
                )}
                {!matchesScreenSize || askDisplay ? (
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={6}
                        className={
                            askDisplay ? 'askDisplay AskContainerParent' : 'AskContainerParent'
                        }
                    >
                        <Asks />
                    </Grid>
                ) : (
                    <ChatBox onClick={handleClick}></ChatBox>
                )}
            </GingerToolbar>
            {dialogComponent}
        </>
    );
});

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', [
                'common',
                'footer',
                'ginger',
                'modal',
            ])),
        },
    };
};

Home.layout = defaultlayout;
export default Home;
