import { createSlice } from '@reduxjs/toolkit';

import type { MenuObj } from '@/src/shared/constants/UserMenuDropdownConstants';

import { applyStateupdate } from '../utils';

interface SelectedMenuState {
    'state/selectedUserMenu': MenuObj | undefined;
}

export const initState: SelectedMenuState = {
    'state/selectedUserMenu': undefined,
};

export const userSelectedMenuSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        selectedUserMenu: (state, action) => {
            return applyStateupdate(state, action);
        },
    },
});

export default userSelectedMenuSlice;
