import { createSlice } from '@reduxjs/toolkit';

import { timeFrameRange } from '@/src/shared/constants/CommonConstants';

import { applyStateupdate } from '../utils';

interface SelectedDateTimeFilter {
    'state/selectedTimeDropDown': object;
    'state/selectedDates': object;
    'state/isCustomSelected': boolean;
    'state/isCancleButtonClicked': boolean;
    'state/isCustomDate': boolean;
}

const defaultStartDate = new Date();
defaultStartDate.setDate(defaultStartDate.getDate() - 6);

export const initState: SelectedDateTimeFilter = {
    'state/selectedTimeDropDown': timeFrameRange[0],
    'state/selectedDates': {
        startDate: defaultStartDate,
        endDate: new Date(),
    },
    'state/isCustomSelected': false,
    'state/isCancleButtonClicked': false,
    'state/isCustomDate': false,
};

export const reportsSectionSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        selectedTimeDropDown: (state, action) => {
            return applyStateupdate(state, action);
        },
        selectedDates: (state, action) => {
            return applyStateupdate(state, action);
        },
        isCustomSelected: (state, action) => {
            return applyStateupdate(state, action);
        },
        isCancleButtonClicked: (state, action) => {
            return applyStateupdate(state, action);
        },
        isCustomDate: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return initState;
        },
    },
});

export default reportsSectionSlice;
