import { createSlice } from '@reduxjs/toolkit';

import type { GlobalPlaceholderData } from '@/src/shared/services/apiServices/responseModel/globalPlaceholder';

import { applyStateupdate } from '../../utils';

interface NudgeDesignState {
    'state/title': string;
    'state/message': any;
    'state/media': File | string;
    'state/personalize': boolean;
    'state/mediaSrc': string;
    'state/nudgeName': string;
    'state/publishNudgeName': string;
    'state/kPIInfo': any;
    'state/prevSelectedKPI': any;
    'state/dynamicFormFields': any;
    'state/selectedKPIFormValue': any;
    'state/updateDynamicFields': any;
    'state/placeholders': any;
    'state/validationError': boolean;
    'state/nudgeSelectedPlaceholder': any;
    'state/editorContentMaxCharCount': number;
    'state/globalPlaceholder': Array<GlobalPlaceholderData>;
    'state/nudgeBasedType': string;
}

export const initState: NudgeDesignState = {
    'state/title': '',
    'state/message': '',
    'state/media': '',
    'state/personalize': false,
    'state/mediaSrc': '',
    'state/nudgeName': '',
    'state/publishNudgeName': '',
    'state/dynamicFormFields': [],
    'state/selectedKPIFormValue': [],
    'state/updateDynamicFields': [],
    'state/validationError': false,
    'state/kPIInfo': {},
    'state/prevSelectedKPI': {},
    'state/placeholders': [],
    'state/nudgeSelectedPlaceholder': [],
    'state/editorContentMaxCharCount': 0,
    'state/globalPlaceholder': [],
    'state/nudgeBasedType': 'schedule',
};

export const nudgeDesignSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        title: (state, action) => {
            return applyStateupdate(state, action);
        },
        message: (state, action) => {
            return applyStateupdate(state, action);
        },
        media: (state, action) => {
            return applyStateupdate(state, action);
        },
        personalize: (state, action) => {
            return applyStateupdate(state, action);
        },
        mediaSrc: (state, action) => {
            return applyStateupdate(state, action);
        },
        nudgeName: (state, action) => {
            return applyStateupdate(state, action);
        },
        publishNudgeName: (state, action) => {
            return applyStateupdate(state, action);
        },
        prevSelectedKPI: (state, action) => {
            return applyStateupdate(state, action);
        },
        kPIInfo: (state, action) => {
            return applyStateupdate(state, action);
        },
        dynamicFormFields: (state, action) => {
            return applyStateupdate(state, action);
        },
        selectedKPIFormValue: (state, action) => {
            return applyStateupdate(state, action);
        },
        updateDynamicFields: (state, action) => {
            const { name, value } = action.payload;
            const parameter = state['state/dynamicFormFields'].find(
                (param: { name: string }) => param.name === name
            );
            if (parameter && parameter.type === 'number') {
                // eslint-disable-next-line radix
                parameter.value = parseInt(value);
            } else {
                parameter.value = value;
            }

            return state;
        },
        resetState: () => {
            return initState;
        },
        updateNudgeDesigneInfo: (state, action) => {
            const updatedInfo = action.payload;
            return {
                ...state,
                ...updatedInfo,
            };
        },
        checkEmptyFields: (state) => {
            const hasEmptyFields = state['state/dynamicFormFields'].some(
                (param: { value: any }) => param.value === ''
            );
            // eslint-disable-next-line no-param-reassign
            state['state/validationError'] = hasEmptyFields;
        },
        placeholders: (state, action) => {
            return applyStateupdate(state, action);
        },
        nudgeSelectedPlaceholder: (state, action) => {
            return applyStateupdate(state, action);
        },
        editorContentMaxCharCount: (state, action) => {
            return applyStateupdate(state, action);
        },
        globalPlaceholder: (state, action) => {
            return applyStateupdate(state, action);
        },
        nudgeBasedType: (state, action) => {
            return applyStateupdate(state, action);
        },
    },
});

export default nudgeDesignSlice;
