import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { labelColor } from '@/src/styles/theme/lightThemeOptions';

export const StyledErrorBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 16px)',
    height: 'auto',
    maxWidth: '1140px',
    padding: theme.spacing(4),
    img: {
        width: '100%',
        height: 'auto',
    },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    maxWidth: theme.spacing(105),
    textAlign: 'center',
    margin: 'auto',
    marginTop: theme.spacing(5),
    color: labelColor,
}));
