import { createSlice } from '@reduxjs/toolkit';

import { applyStateupdate } from '../utils';

interface KpiState {
    'state/kpiName': string;
    'state/description': string;
    'state/triggerType': string;
    'state/selectedSystem': string;
    'state/selectedEvent': string;
    'state/handlerType': string;
    'state/functionName': string;
    'state/parameters': object;
    'state/allowUsers': boolean;
    'state/nudgeName': string;
    'state/responseText': string;
}

export const initState: KpiState = {
    'state/kpiName': '',
    'state/description': '',
    'state/triggerType': '',
    'state/selectedSystem': '',
    'state/selectedEvent': '',
    'state/handlerType': '',
    'state/functionName': '',
    'state/parameters': {},
    'state/allowUsers': true,
    'state/nudgeName': '',
    'state/responseText': '',
};

export const kpiSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        kpiName: (state, action) => {
            return applyStateupdate(state, action);
        },
        description: (state, action) => {
            return applyStateupdate(state, action);
        },
        triggerType: (state, action) => {
            return applyStateupdate(state, action);
        },
        nudgeName: (state, action) => {
            return applyStateupdate(state, action);
        },
        handlerType: (state, action) => {
            return applyStateupdate(state, action);
        },
        functionName: (state, action) => {
            return applyStateupdate(state, action);
        },
    },
});

export default kpiSlice;
