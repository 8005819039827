import { Paper, styled } from '@mui/material';

import { white } from '@/src/styles/theme/lightThemeOptions';

export const GingerCares = styled(Paper)({
    padding: 0,
    background: white,
    borderRadius: '20px 20px 0px 0px',
    display: 'flex',
    mozFlexDirection: 'column',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
});
