import type { GetStaticProps } from 'next';
import Image from 'next/image';
import router from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useEffect } from 'react';

import { AuthContext } from '@/src/shared/contexts/AzureAuthProvider';
import { getBlobUrl } from '@/src/utils/commonUtil';

import {
    StyledErrorBox,
    StyledTypography,
} from '../../modules/ErrorPagesStyles/ErrorPagesStyle.style';

const SessionTimeOut = () => {
    const { t } = useTranslation(['error']);
    const { login } = React.useContext(AuthContext);

    useEffect(() => {
        const handleLoginClick = async (e: any) => {
            e.preventDefault();
            router.push('/');
        };

        const loginLink = document.getElementById('loginLink');
        if (loginLink) {
            loginLink.addEventListener('click', handleLoginClick);
        }

        return () => {
            if (loginLink) {
                loginLink.removeEventListener('click', handleLoginClick);
            }
        };
    }, [login]);

    return (
        <StyledErrorBox>
            <Image src={getBlobUrl('session_timed_out')} alt="Image" width={80} height={20} />
            <StyledTypography variant="body2">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('error.session.timeout.message', {
                            login: `<a href="#" id="loginLink">${t('error.login')}</a>`,
                        }),
                    }}
                />
            </StyledTypography>
        </StyledErrorBox>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common', 'error'])),
        },
    };
};

SessionTimeOut.layout = 'sessionTimeOut';
export default SessionTimeOut;
