import type { Theme, ThemeOptions } from '@mui/material/styles';

// eslint-disable-next-line import/no-cycle

export const primaryOrange = '#F58937';
export const baseOrange = '#F6F3F2';
export const primaryOrangeWithAlpha = (alpha: number) => `rgba(245, 137, 55, ${alpha})`;
export const primaryHoverOrange = '#E4741E';
export const primaryDisabled = '#e6e6e6';
export const primaryDisabledText = '#bbb';
export const orangeLight = '#f06b33';
export const secondaryBlue = '#314d85';
export const error = '#ff5a5f';
export const success = '#02b474';
export const warning = '#eabb42';
export const bodyTextColor = '#101010';
export const black = '#000';
export const white = '#fff';
export const RoseWhiteColor = '#fff7f7';
export const ReportBackgroundColor = '#F7F4F3';
export const FilterBadgeBackgroundColor = '#E26155';
export const whiteWithAlpha = (alpha: number) => `rgba(255, 255, 255, ${alpha})`;
export const BlackWithAlpha = (alpha: number) => `rgba(0, 0, 0, ${alpha})`;
export const primaryGray = '#999999';
export const textBoxStroke = '#e9edf0';
export const textBoxFill = '#fbfcfc';
export const inputBackground = '#F7F7F7';
export const borderColor = '#EEEEEE';
export const borderColorWithAlpha = (alpha: number) => `rgba(238, 238, 238, ${alpha})`;
export const placeholderColor = '#A4A4A4';
export const tableTextBlack = '#613E35';
export const lightBlackColor = '#3E2723';
export const lightGrayBlueColor = '#dadde9';
export const primaryGreenColor = '#3AAB94';
export const warningYellowColor = '#F9A826';
export const defaultGrayColor = '#BFBFBF';
export const dividerColor = '#E2E2E2';
export const FeedBackgroundColor = '#E7F8F3';
export const LimeadeColor = '#67c100';
export const AzureRadianceColor = '#03a6ff';
export const greyColor = '#A1887F';
export const successCheckGreen = '#41AD49';
export const disableCheckGrey = '#D9D9D9';
export const lightBgGrayColor = '#f0f0f0';
export const lightWightGrayColor = '#d1d1d1';
export const disableCheckGreyWithAlpha = (alpha: number) => `rgba(217, 217, 217, ${alpha})`;
export const primaryBlueColor = '#255DA0';
export const lightOrange = '#FDE7D7';
export const popperDividerColor = '#EFEBE9';
export const popperDividerColorWithAlpha = (alpha: number) => `rgba(239, 235, 233, ${alpha})`;
export const darkGrey = '#463A38';
export const labelColor = '#89736D';
export const welcomeLabelColor = '#485156';
export const outlinedHover = '#FFF5ED';
export const checkboxGrey = '#C8C6C4';
export const alertRed = '#EE404C';
export const errorRed = '#FF4B55';
export const disabledItemColor = '#979797';
export const SyncingStatus = '#BDEFFF';
export const SyncingStatusText = '#41759B';
export const skeletonBackgroundColor = 'linear-gradient(133.69deg, #F5F5F5 45.33%, #DFDFDF 100%)';
export const DialogBackgroundColor = `linear-gradient(133.69deg, ${baseOrange} 45.33%, #DFDFDF 100%)`;
export const hoverPaleOrange = '#FFE6D3';
export const hoverLightPaleOrange = '#FFE6D380';
export const badgePrimaryBgColor = '#FFE6BD';
export const badgePrimaryTextColor = '#F8A624';
export const badgeSuccessBgColor = '#B0F1E4';
export const badgeSuccessTextColor = '#3AAB94';
export const badgeDangerBgColor = '#F6E2E3';
export const badgeDangerTextColor = '#EA454E';
export const DateTimeSeparatorColor = '#8080804d';
export const CodGrayColor = '#1a1a1a21';
export const BrownGrayColor = '#918e8e47';
export const rowBackgroundColor = '#F9F9F9';
export const learningOrange = '#D9B32B';
export const learningLightOrange = '#FFEDBD';
export const learnedLightGreen = '#C1EDE4';
export const learningRed = '#da332b';
export const learningLightRed = '#edc0c0';
export const cancelledStatusColor = '#96A6FC';
export const failedStatusColor = '#EE404C';
export const unansweredStatusColor = '#BFBFBF';

export const folderBackgroundColor = '#F9F9F9';
export const searchIconColor = '#22354A4D';
export const opaquePrimaryOrange = '#F5893799';
export const careSectionTableBorder = '#dee2e6';
export const careSectionHeaderColor = '#e9ecef';

export const donutBlue = '#5E8FC9';
export const donutYellow = '#FFCD4C';
export const donutGreen = '#67E09C';
export const donutGrey = '#C1C2C2';
export const donutPurple = '#735EC9';
export const donutPeach = '#FFB075';
export const BarPink = '#FFC3C8';
export const BarYellow = '#F3B344';
export const BarGreen = '#67E09C';
export const BarBlue = '#4790E6';
export const BarSkyBlue = '#53E2F9';
export const OrangeGradientShade1 = '#F5F2F1';
export const OrangeGradientShade2 = '#E0D3CF';

export const analyticsSeparator = '#F0EBE9';

export const disabledFieldBorderColor = 'rgba(0, 0, 0, 0.26)';
export const primaryOrangefilter =
    'invert(68%) sepia(13%) saturate(6825%) hue-rotate(336deg) brightness(101%) contrast(92%)';
export const primaryDisabledFilter =
    'brightness(0) saturate(100%) invert(94%) sepia(0%) saturate(989%) hue-rotate(1deg) brightness(116%) contrast(80%)';
export const darkGreyfilter =
    'invert(22%) sepia(6%) saturate(1182%) hue-rotate(321deg) brightness(95%) contrast(92%)';
export const lightBlackFilter =
    'invert(14%) sepia(49%) saturate(375%) hue-rotate(323deg) brightness(98%) contrast(95%)';
export const whiteFilter =
    'invert(94%) sepia(79%) saturate(0%) hue-rotate(97deg) brightness(108%) contrast(101%);';
export const whiteColor =
    'invert(94%) sepia(79%) saturate(0%) hue-rotate(97deg) brightness(108%) contrast(101%);';

export const lightGrayColor =
    'brightness(0) saturate(100%) invert(56%) sepia(8%) saturate(796%) hue-rotate(330deg) brightness(98%) contrast(85%)';
export const lightDarkGrayColor =
    'brightness(0) saturate(100%) invert(76%) sepia(2%) saturate(4278%) hue-rotate(356deg) brightness(82%) contrast(58%)';
export const whiteFilterColor =
    'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(206deg) brightness(103%) contrast(101%)';
export const darkGrayColor =
    'brightness(0) saturate(100%) invert(62%) sepia(2%) saturate(4137%) hue-rotate(326deg) brightness(74%) contrast(90%);';
export const disabledFilter =
    'brightness(45%) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(206deg) brightness(103%) contrast(101%)';
const getBlobIconUrl = (fileName: string) => {
    const storageAccountName = process.env.NEXT_PUBLIC_STORAGE_ACCOUNT_NAME;
    const containerName = `gingerbot/org/icons`;
    const imageUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}.svg`;
    return imageUrl;
};
const typographyOptions = (theme: Theme) => {
    return {
        htmlFontSize: 16,
        root: {
            '&.normalFont': {
                fontWeight: 'normal',
            },
        },
        h1: {
            fontSize: theme.spacing(8),
            margin: '0 0 1.25rem 0',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(6),
                lineHeight: 1.33,
                letterSpacing: '0.0125rem',
                fontWeight: 600,
                margin: '0 0 1rem 0',
            },
            '&.semiBold': {
                fontWeight: 600,
            },
        },
        h2: {
            fontSize: theme.spacing(7),
            margin: '0 0 1rem 0',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(5),
                lineHeight: 1.4,
                fontWeight: 600,
            },
            '&.semiBold': {
                fontWeight: 600,
            },
            '&.normalFont': {
                fontWeight: 'normal',
            },
        },
        h3: {
            fontSize: theme.spacing(6),
            lineHeight: 1.44,
            margin: '0 0 1rem 0',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4.5),
                lineHeight: 1.44,
                fontWeight: 600,
            },
            '&.semiBold': {
                fontWeight: 600,
            },
            '&.normalFont': {
                fontWeight: 'normal',
            },
        },
        h4: {
            fontSize: theme.spacing(5),
            lineHeight: 1.5,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4),
                lineHeight: 1.5,
                fontWeight: 600,
            },
            '&.semiBold': {
                fontWeight: 600,
            },

            '&.normalFont': {
                fontWeight: 'normal',
            },
        },
        h5: {
            fontSize: theme.spacing(4.5),
            lineHeight: 1.5,
            margin: '0 0 0.5rem 0',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(4),
                lineHeight: 1.5,
                fontWeight: 600,
            },
            '&.semiBold': {
                fontWeight: 600,
            },

            '&.normalFont': {
                fontWeight: 'normal',
            },
        },
        h6: {
            fontFamily: ['Equip_Bold', 'sans-serif'].join(','),
            fontSize: theme.spacing(5),
            color: lightBlackColor,
        },
        body1: {
            fontSize: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(3.5),
            },
        },
        body2: {
            fontSize: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(3),
            },
        },
        caption: {
            color: labelColor,
            fontSize: theme.spacing(3.5),
        },
        customNestedTypography: {
            // use in another case in future if needed
            fontSize: theme.spacing(3),
            fontWeight: '400',
        },
        boldHeading: {
            fontFamily: 'Equip_Bold',
        },
        formHeading: {
            color: labelColor,
            fontSize: theme.spacing(3.5),
        },
    };
};

export const withTheme = (theme: Theme) => {
    theme.typography = {
        ...theme.typography,
        ...typographyOptions(theme),
    };
    return theme;
};

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        customNestedTypography: true;
        boldHeading: true;
        formHeading: true;
    }
}
const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: `${primaryOrange}`,
        },
        secondary: {
            main: `${secondaryBlue}`,
        },
        success: {
            main: `${success}`,
        },
        info: {
            main: `${secondaryBlue}`,
        },
        warning: {
            main: `${warning}`,
        },
        error: {
            main: `${error}`,
        },
        primaryOrange: '#F58937',
        baseOrange: '#F6F3F2',
        primaryOrangeWithAlpha: (alpha: number) => `rgba(245, 137, 55, ${alpha})`,
        primaryHoverOrange: '#E4741E',
        primaryDisabled: '#e6e6e6',
        primaryDisabledText: '#bbb',
        orangeLight: '#f06b33',
        secondaryBlue: '#314d85',
        bodyTextColor: '#101010',
        black: '#000',
        white: '#fff',
        whiteWithAlpha: (alpha: number) => `rgba(255, 255, 255, ${alpha})`,
        primaryGray: '#999999',
        textBoxStroke: '#e9edf0',
        textBoxFill: '#fbfcfc',
        inputBackground: '#F7F7F7',
        borderColor: '#EEEEEE',
        placeholderColor: '#A4A4A4',
        tableTextBlack: '#613E35',
        lightBlackColor: '#3E2723',
        primaryGreenColor: '#3AAB94',
        warningYellowColor: '#F9A826',
        defaultGrayColor: '#BFBFBF',
        dividerColor: '#E2E2E2',
        greyColor: '#A1887F',
        successCheckGreen: '#41AD49',
        disableCheckGrey: '#D9D9D9',
        disableCheckGreyWithAlpha: (alpha: number) => `rgba(217, 217, 217, ${alpha})`,
        primaryBlueColor: '#255DA0',
        lightOrange: '#FDE7D7',
        popperDividerColor: '#EFEBE9',
        popperDividerColorWithAlpha: (alpha: number) => `rgba(239, 235, 233, ${alpha})`,
        darkGrey: '#463A38',
        labelColor: '#89736D',
        outlinedHover: '#FFF5ED',
        checkboxGrey: '#C8C6C4',
        alertRed: '#EE404C',
        errorRed: '#FF4B55',
        skeletonBackgroundColor: 'linear-gradient(133.69deg, #F5F5F5 45.33%, #DFDFDF 100%)',
        hoverPaleOrange: '#FFE6D3',
        hoverLightPaleOrange: '#FFE6D380',
        badgePrimaryBgColor: '#FFE6BD',
        badgePrimaryTextColor: '#F8A624',
        badgeSuccessBgColor: '#B0F1E4',
        badgeSuccessTextColor: '#3AAB94',
        badgeDangerBgColor: '#F6E2E3',
        badgeDangerTextColor: '#EA454E',
        DateTimeSeparatorColor: '#8080804d',
        rowBackgroundColor: '#F9F9F9',
        learningOrange: '#D9B32B',
        learningLightOrange: '#FFEDBD',
        learnedLightGreen: '#C1EDE4',
        learningRed: '#da332b',
        learningLightRed: '#edc0c0',
        cancelledStatusColor: '#96A6FC',
        failedStatusColor: '#EE404C',
        unansweredStatusColor: '#BFBFBF',
        folderBackgroundColor: '#F9F9F9',
        searchIconColor: '#22354A4D',
        opaquePrimaryOrange: '#F5893799',
        careSectionTableBorder: '#dee2e6',
        careSectionHeaderColor: '#e9ecef',
        donutBlue: '#5E8FC9',
        donutYellow: '#FFCD4C',
        donutGreen: '#67E09C',
        donutGrey: '#C1C2C2',
        donutPurple: '#735EC9',
        donutPeach: '#FFB075',
        BarPink: '#FFC3C8',
        BarYellow: '#F3B344',
        BarGreen: '#67E09C',
        BarBlue: '#4790E6',
        BarSkyBlue: '#53E2F9',
        OrangeGradientShade1: '#F5F2F1',
        OrangeGradientShade2: '#E0D3CF',
        analyticsSeparator: '#F0EBE9',
        disabledFieldBorderColor: 'rgba(0, 0, 0, 0.26)',
    },
    shape: {
        borderRadius: 8,
    },
    spacing: (factor: number) => `${0.25 * factor}rem`,
    transitions: {
        create: (props: any) => {
            const { duration, easing, property } = props;
            return `${property} ${duration}ms ${easing}`;
        },
        easing: {
            sharp: 'cubic-bezier(0.4, 0, 0.2, 1)',
        },
        duration: {
            enteringScreen: 225,
            leavingScreen: 195,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 900,
            lg: 1200,
            xl: 1440,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                },
                html: {
                    border: 0,
                    verticalAlign: 'baseline',
                    minHeight: '100%',
                    width: '100%',
                    fontWeight: '400',
                    fontSize: '1rem',
                    padding: 0,
                    margin: 0,
                },
                body: {
                    background: 'linear-gradient(to right,#f5f2f1 45%,#e0d3cf 100%)',
                    minHeight: '100%',
                    width: '100%',
                    border: 0,
                    fontFamily: ['Equip_Regular', 'sans-serif'].join(','),
                    boxSizing: 'border-box',
                    overflow: 'hidden', // to prevent outer scroll and allow fixed header
                    color: lightBlackColor,
                    webkitOverflowScrolling: 'touch',
                },
                '#__next': {
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                },

                link: {
                    textDecoration: 'none',
                },
                a: {
                    textDecoration: 'none',
                    color: `${primaryOrange}`,
                    '&.secondary': {
                        color: `${secondaryBlue}`,
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    background: skeletonBackgroundColor,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 600,
                    boxSizing: 'border-box',
                    fontFamily: ['Equip_Regular', 'sans-serif'].join(','),
                },
                sizeSmall: {
                    paddingInline: 20,
                    paddingBlock: 5,
                    fontSize: '0.75rem',
                },
                sizeMedium: {
                    paddingInline: 37,
                    paddingBlock: 12,
                    fontSize: '0.9rem',
                },
                sizeLarge: {
                    paddingInline: 44,
                    paddingBlock: 8,
                    fontSize: '1rem',
                    fontFamily: ['Equip_Extended_Bold', 'sans-serif'].join(','),
                },
                containedPrimary: {
                    backgroundColor: primaryOrange,
                    borderRadius: 12,
                    fontWeight: 700,
                    color: `${white}`,
                    '&:hover': {
                        opacity: 0.9,
                        background: primaryHoverOrange,
                    },
                    ':disabled': {
                        backgroundColor: `${primaryDisabled}`,
                        backgroundImage: 'none',
                        color: `${primaryDisabledText}`,

                        '&:hover': {
                            opacity: 1,
                        },
                    },
                },
                containedSizeMedium: {
                    padding: '8px 20px',
                    borderRadius: 5,
                    '&:hover': {
                        borderRadius: 5,
                    },
                    ':disabled': {
                        backgroundColor: `${primaryDisabled}`,
                        backgroundImage: 'none',
                        color: `${primaryDisabledText}`,
                        border: `1px solid ${primaryDisabled}`,
                        '&:hover': {
                            opacity: 1,
                        },
                    },
                },
                outlinedSizeMedium: {
                    padding: '8px 20px',
                    borderRadius: 5,
                    backgroundColor: white,
                    '&:hover': {
                        borderRadius: 5,
                    },
                },
                outlined: {
                    borderRadius: 12,
                    borderWidth: '0.125rem',
                    borderColor: `${lightOrange}`,
                    '&:hover': {
                        borderColor: `${primaryOrange}`,
                        borderRadius: 12,
                        borderWidth: '0.125rem',
                        backgroundColor: `${outlinedHover}`,
                    },
                    ':disabled': {
                        backgroundImage: 'none',
                        color: primaryOrange,
                        opacity: '40%',
                        borderColor: `${lightOrange}`,
                    },
                    '&.MuiButton-outlinedSizeSmall': {
                        paddingBlock: 3,
                    },
                    '&.MuiButton-outlinedSizeMedium': {
                        paddingBlock: '0.4rem',
                    },
                    '&.MuiButton-outlinedSizeLarge': {
                        paddingBlock: 6,
                    },
                },
                text: {
                    paddingBlock: 8,
                    fontFamily: ['Equip_Extended_Bold', 'sans-serif'].join(','),
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderColor: `${borderColor}`,
                    background: `${inputBackground}`,
                    borderRadius: 8,
                    color: `${lightBlackColor}`,

                    outlined: {
                        borderColor: `${borderColor}`,
                        background: `${textBoxFill}`,
                        padding: '1.25rem 0.75rem',
                    },
                    '&:disabled': {
                        '&:hover fieldset': {
                            borderColor: `${inputBackground}`,
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: `${lightBlackColor}`,
                        lineHeight: '14px',
                        top: '0',
                        bottom: '0',
                        '&.MuiInputLabel-sizeSmall': {
                            top: 0,
                            lineHeight: '1.4rem',
                        },
                        '&.Mui-focused': {
                            color: `${bodyTextColor}`,
                            borderWidth: 1,
                        },

                        '&.Mui-error': {
                            color: `${primaryGray}`,
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        padding: '6px',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-input': {
                            fontSize: '0.875rem',
                            color: lightBlackColor,
                            padding: '0 6px',
                            backgroundColor: `${inputBackground}`,
                            '::placeholder': {
                                fontSize: '0.875rem',
                                color: `${placeholderColor}`,
                            },
                            '& fieldset': {
                                border: '0',
                            },
                            '& MuiOutlinedInput-notchedOutline': {
                                border: '0',
                            },
                        },
                        '& .Mui-disabled': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${borderColor}`,
                            },
                        },
                        '& .MuiInputBase-inputSizeSmall': {
                            padding: '0.5rem 0 0.5rem 0.75rem',
                            height: '1.5rem',
                        },
                        '& fieldset': {
                            borderColor: `${inputBackground}`,
                        },
                        '&:hover fieldset': {
                            borderColor: `${inputBackground}`,
                        },
                        '&:focus-visible fieldset': {
                            borderColor: `${inputBackground}`,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: `${inputBackground}`,
                            color: `${bodyTextColor}`,
                            fontSize: '0.875rem',
                        },

                        '&.MuiInputBase-adornedEnd': {
                            paddingRight: '0.25rem',

                            svg: {
                                width: '1.25rem',
                                height: '1.25rem',
                            },
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    borderColor: `${checkboxGrey}`,
                    height: '18px',
                    width: '18px',
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        '&.MuiDialog-paper': {
                            overflowY: 'inherit',
                        },

                        '& .MuiButtonBase-root': {
                            '&.closeButton': {
                                position: 'absolute',
                                width: `2.25rem`,
                                height: `2.25rem`,
                                right: '-1rem',
                                top: '-1rem',
                                background: `${white}`,
                                border: '4px solid rgba(0,0,0,0.5)',
                                padding: '0',

                                svg: {
                                    width: '0.75rem',
                                    height: '0.75rem',
                                    fill: '#212121',
                                },
                            },
                        },
                    },
                },
                paperWidthLg: {
                    maxWidth: '64rem',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {},
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    background: '#FFFFFF',
                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
                    fontWeight: 400,
                    '& .MuiTableCell-head': {
                        fontSize: 12,
                        color: tableTextBlack,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0 15px',
                    fontSize: 14,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    padding: '12px 15px 12px 15px',
                    borderRadius: 0,
                    height: '42px',
                    minHeight: '42px',
                    '&:nth-of-type(odd)': {
                        backgroundColor: rowBackgroundColor,
                    },
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    fontFamily: ['Equip_Extended_Bold', 'sans-serif'].join(','),
                    padding: 0,
                    '& .MuiStep-root': {
                        borderBottom: '0 none',
                        padding: 0,
                        position: 'relative',
                        '& .active': {
                            '&:after': {
                                content: '" "',
                                display: 'block',
                                position: 'absolute',
                                backgroundImage: `linear-gradient(to right, transparent, transparent), url(${getBlobIconUrl(
                                    'stepBorder'
                                )})`,
                                backgroundPosition: 'left bottom',
                                backgroundClip: 'content-box, border-box',
                                paddingBottom: '10px',
                                backgroundRepeat: 'no-repeat',
                                width: '86%',
                                bottom: 0,
                                backgroundSize: '100%',
                            },
                        },
                    },
                    '& .MuiStepLabel-root': {
                        textTransform: 'uppercase',
                        marginRight: '20px',
                        marginLeft: '10px',
                        fontWeight: 700,
                        '& .MuiStepLabel-label': {
                            color: `${greyColor}`,
                        },
                        '& .Mui-active': {
                            fontWeight: 700,
                            color: `${lightBlackColor}`,
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: `${white}`,
                    borderRadius: '20px',
                    flexBasis: 'content',
                    border: `1px solid ${borderColor}`,
                    fontFamily: ['Equip_Bold', 'sans-serif'].join(','),
                    '& .MuiChip-label': {
                        color: `${lightBlackColor}`,
                    },
                    '& .MuiChip-deleteIcon': {
                        color: primaryOrange,
                        ':hover': {
                            opacity: 0.9,
                            color: primaryHoverOrange,
                        },
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    color: labelColor,
                    fontSize: '1rem',
                    '& .MuiCheckbox-root': {
                        color: checkboxGrey,
                        margin: 9,
                        '& svg': {
                            width: '1rem',
                            height: '1rem',
                        },
                    },
                    '& .Mui-checked': {
                        color: primaryOrange,
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: `1px solid ${inputBackground}`,
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: labelColor,
                    letterSpacing: '0',
                    fontFamily: ['Equip_Regular', 'sans-serif'].join(','),
                },
                focused: {},
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    '& svg': {
                        height: '20px',
                        width: '20px',
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: checkboxGrey,
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    fontFamily: ['Equip_Regular', 'sans-serif'].join(','),
                    fontWeight: '500',
                    fontSize: '0.75rem',
                    padding: '3px 10px',
                    borderRadius: '10px',
                },
                colorPrimary: {
                    background: badgePrimaryBgColor, // Replace with your desired primary color
                    color: badgePrimaryTextColor,
                },
                colorSuccess: {
                    background: badgeSuccessBgColor, // Replace with your desired secondary color
                    color: badgeSuccessTextColor,
                },
                colorError: {
                    background: badgeDangerBgColor, // Replace with your desired error color
                    color: badgeDangerTextColor,
                },
            },
        },
    },
};

export default lightThemeOptions;
