/* eslint-disable no-restricted-syntax */
import MenuIcon from '@mui/icons-material/Menu';
import {
    Backdrop,
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
} from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Logo } from '@/src/components/Header/Logo';
import { TextOnlyTooltip } from '@/src/components/Tooltip/TextOnlyTooltip';
import { mediumScreen } from '@/src/shared/constants/MediaQueryConstants';
import type { MenuObj } from '@/src/shared/constants/UserMenuDropdownConstants';
import type { CategoryPermission } from '@/src/shared/services/apiServices/responseModel/userApiType';
import type { RootState } from '@/src/store';
import { createNudgeSlice } from '@/src/store/apps/nudge';
import {
    darkGreyfilter,
    hoverLightPaleOrange,
    lightOrange,
    primaryOrangefilter,
    white,
} from '@/src/styles/theme/lightThemeOptions';

import { AdminDrawer, DrawerHeader } from '../adminLayout.style';

interface SideBarPRops {
    open: boolean;
    handleToggleSideNav: () => void;
}
const Sidebar = ({ open, handleToggleSideNav }: SideBarPRops) => {
    const selectedMenu = useSelector(
        (state: RootState) => state.selectedMenu['state/selectedUserMenu']
    );
    const [sideNav, setSideNav] = useState<any[]>([]);
    const pageName = useSelector((state: RootState) => state.createNudge['state/pageType']);
    const route = useRouter();
    const dispatch = useDispatch();

    function findMenuByLink(obj: any, link: string): MenuObj[] {
        for (let i = 0; i < obj?.categories?.length || 0; i += 1) {
            const firstLevelMenu = obj.categories;
            if (`/${firstLevelMenu[i].name}` === link) {
                /* @ts-ignore */
                return [{ menu: firstLevelMenu }];
            }
            if (firstLevelMenu[i].permissions) {
                for (let j = 0; j < firstLevelMenu[i].permissions.length; j += 1) {
                    const secondLevelMenu = firstLevelMenu[i]?.permissions?.map(
                        (item: CategoryPermission) => {
                            return {
                                ...item,
                                name: `/${firstLevelMenu[i].name}/${item.name}`,
                            };
                        }
                    );
                    if (link.includes(secondLevelMenu[j].name)) {
                        /* @ts-ignore */
                        return [{ menu: secondLevelMenu }];
                    }
                }
            }
        }
        return [];
    }

    useEffect(() => {
        if (selectedMenu) {
            const menuArray = selectedMenu || [];
            const matchingMenu = findMenuByLink(menuArray, route.pathname);
            setSideNav(matchingMenu);
        }
    }, [selectedMenu, route.pathname]);

    const handleClick = (link: string | undefined) => {
        if ((!link?.includes(pageName) || pageName === '') && link)
            dispatch(createNudgeSlice.actions.resetFilters());
    };

    const isTabScreen: boolean = useMediaQuery(mediumScreen);

    return (
        <>
            <AdminDrawer
                variant="permanent"
                data-testid="sidebar"
                open={open}
                isTabScreen={isTabScreen.toString()}
            >
                <DrawerHeader
                    style={{
                        paddingLeft: '0.75rem',
                        paddingRight: '0.75rem',
                        position: 'relative',
                        top: '0.1em',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            width: '100%',
                        }}
                    >
                        {isTabScreen ? (
                            <IconButton
                                color="inherit"
                                aria-label="menu"
                                style={{ display: 'hidden', paddingLeft: '0.2em' }}
                                onClick={handleToggleSideNav}
                            >
                                <MenuIcon />
                            </IconButton>
                        ) : null}
                        <Logo />
                    </Box>
                </DrawerHeader>
                <Divider />
                <List>
                    {sideNav[0] &&
                        sideNav[0]?.menu &&
                        sideNav[0].menu.map((list: CategoryPermission, index: number) => (
                            <Link href={list.name} key={index}>
                                <ListItem
                                    key={index}
                                    disablePadding
                                    sx={{
                                        display: 'block',
                                        background: route.pathname.includes(list.name || '')
                                            ? lightOrange
                                            : white,
                                    }}
                                    onClick={() => handleClick(list?.name)}
                                >
                                    <ListItemButton
                                        sx={[
                                            {
                                                minHeight: 56,
                                                minWidth: 56,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            },
                                            {
                                                '&:hover': {
                                                    backgroundColor: hoverLightPaleOrange,
                                                },
                                            },
                                        ]}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    filter: route.pathname.includes(list.name || '')
                                                        ? primaryOrangefilter
                                                        : darkGreyfilter,
                                                }}
                                            >
                                                <TextOnlyTooltip
                                                    keyData={index}
                                                    title={list.displayName}
                                                >
                                                    <Image
                                                        src={list.iconUrl}
                                                        alt={list.displayName}
                                                        width={24}
                                                        height={24}
                                                    />
                                                </TextOnlyTooltip>
                                            </Box>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={list.displayName}
                                            sx={{ opacity: open ? 1 : 0 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        ))}
                </List>
            </AdminDrawer>
            {isTabScreen ? (
                <Backdrop
                    sx={{ color: white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleToggleSideNav}
                ></Backdrop>
            ) : null}
        </>
    );
};

export default Sidebar;
