import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';

import CustomScrollbars from '@/src/components/CustomScrollBar';
import { smallScreenTab } from '@/src/shared/constants/MediaQueryConstants';
import useErrorModalHooks from '@/src/shared/hooks/useErrorModalHooks';
import type { FeedApiResponse } from '@/src/shared/services/apiServices/responseModel/feedsType';
import { updateFeedsReadStatus } from '@/src/shared/services/apiServices/updateFeedsReadStatus';

import GingerCareSkeleton from './gingerCareSkeleton';
import { Feed, FeedPointer, FeedsContainer, FeedWrapper } from './style';

const AdaptiveCards = dynamic(() => import('../../../../../components/AdaptiveCard/AdaptiveCard'), {
    ssr: false,
});

interface FeedsProps {
    userFeeds: FeedApiResponse[];
    setUnreadFeedsCount: (unreadCount: number) => void;
}

export const Feeds: React.FC<FeedsProps> = ({ userFeeds, setUnreadFeedsCount }) => {
    const [isAdaptiveCardLoad, setAdptavieCardLoad] = useState(false);
    const [feedsData, setFeedsData] = useState<FeedApiResponse[]>(userFeeds);
    const isMobile = useMediaQuery(smallScreenTab);

    const { openDialog, setResponseErrorContent, dialogComponent } = useErrorModalHooks();

    useEffect(() => {
        setFeedsData(userFeeds);
    }, [userFeeds]);

    useEffect(() => {
        if (feedsData?.length) {
            const unreadCount = feedsData.filter((feed) => !feed.isRead).length;
            setUnreadFeedsCount(unreadCount);
        }
    }, [feedsData]);

    const feedCountReadUnread = async (inView: boolean, id: string, isRead: boolean) => {
        if (inView && !isRead) {
            const response: any = await updateFeedsReadStatus(id);
            if (response.error || response.errors) {
                setResponseErrorContent(response);
                openDialog();
            } else if (response?.data?.code === 'OK' && response.data.data !== null) {
                const data = [...feedsData];
                const targetIndex = data.findIndex((d) => d.id === id);
                data[targetIndex].isRead = true;
                setFeedsData(data);
            }
        }
    };
    return (
        <>
            <FeedsContainer data-testid="feeds">
                <CustomScrollbars id="feed-header">
                    {!isAdaptiveCardLoad && <GingerCareSkeleton />}
                    {feedsData &&
                        feedsData?.length > 0 &&
                        feedsData?.map((feeds: FeedApiResponse, indexCount: number) => (
                            <Box position="relative" key={feeds.id} data-testid="feed-wrapper">
                                <FeedWrapper key={feeds.id} className={`feeds`}>
                                    <InView
                                        onChange={(inView) => {
                                            feedCountReadUnread(inView, feeds.id, feeds.isRead);
                                        }}
                                        triggerOnce={true}
                                        initialInView={true}
                                        key={feeds.id}
                                        threshold={isMobile ? 0 : 0.2}
                                        className="feed_inview"
                                    >
                                        <Feed>
                                            {isAdaptiveCardLoad && (
                                                <FeedPointer
                                                    sx={{
                                                        visibility: feeds.isRead
                                                            ? 'hidden'
                                                            : 'visible',
                                                    }}
                                                    data-testid="feed-pointer"
                                                ></FeedPointer>
                                            )}

                                            <Box className="adaptive-content" position="relative">
                                                <AdaptiveCards
                                                    cardJson={JSON.parse(feeds?.nudgeTemplate)}
                                                    adaptiveCardLoad={(val: boolean) =>
                                                        setAdptavieCardLoad(val)
                                                    }
                                                    indexCount={indexCount}
                                                    isAdaptiveCardLoad={isAdaptiveCardLoad}
                                                />
                                            </Box>
                                        </Feed>
                                    </InView>
                                </FeedWrapper>
                            </Box>
                        ))}
                </CustomScrollbars>
            </FeedsContainer>
            {dialogComponent}
        </>
    );
};
