import { Backdrop } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'next/image';

import { white } from '@/src/styles/theme/lightThemeOptions';
import { getBlobUrl } from '@/src/utils/commonUtil';

const Loader = () => {
    return (
        <Backdrop sx={{ color: white, zIndex: '10000' }} open={true} data-testid="loader_backdrop">
            <Box
                sx={{
                    width: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '99',
                }}
            >
                <Image src={getBlobUrl('loading')} alt="My SVG" width={174} height={174} />
            </Box>
        </Backdrop>
    );
};

export default Loader;
