import { nudgeConfigureSlice } from './configure';
import { createNudgeSlice } from './createNudge';
import { nudgeDesignSlice } from './deisgn';
import { nudgePublishSlice } from './publish';
import { nudgeScheduleSlice } from './schedule';
import { nudgeTriggerSlice } from './trigger';

export {
    createNudgeSlice,
    nudgeConfigureSlice,
    nudgeDesignSlice,
    nudgePublishSlice,
    nudgeScheduleSlice,
    nudgeTriggerSlice,
};
