import { Box, styled } from '@mui/system';

import {
    careSectionHeaderColor,
    careSectionTableBorder,
    FeedBackgroundColor,
    hoverPaleOrange,
    lightBlackColor,
    popperDividerColor,
    primaryGreenColor,
    primaryOrange,
} from '@/src/styles/theme/lightThemeOptions';

export const FeedsContainer = styled(Box)({
    overflow: 'auto',
    height: 'calc(100% - 0px)',
    position: 'relative',
    paddingBottom: '9px',
    '@media(max-width: 767px)': {
        height: 'calc(100% - 85px)',
    },
    '& .custom-scroll-bar:hover': {
        'div:last-child': {
            opacity: '1 !important',
        },
    },
});
export const FeedWrapper = styled(Box)(({ theme }) => ({
    margin: theme.spacing(5, 10, 5, 5),
    paddingBottom: '9px',
    position: 'relative',
    display: 'flex',
    alignItems: 'baseline',

    '& .feed_inview': {
        width: '100%',
    },
}));

export const Feed = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    alignItems: 'baseline',

    '.adaptive-content': {
        WebkitFontSmoothing: 'unset !important',
        width: '100%',
        wordBreak: 'break-word',
        paddingBottom: '29px',
        borderBottom: `1px solid ${popperDividerColor}`,
        color: lightBlackColor,
        '& .ac-textBlock': {
            flex: 'unset !important',
            whiteSpace: 'unset !important',
            p: {
                lineHeight: theme.spacing(5),
                marginBlock: theme.spacing(0),
            },
            img: {
                maxWidth: '100%',
            },
            ol: {
                marginBlock: theme.spacing(0),
            },
            ul: {
                marginBlock: theme.spacing(0),
            },
        },
        'div[role=table]': {
            border: `1px solid ${careSectionTableBorder}`,
            borderCollapse: 'collapse',
            width: 'auto !important',

            'div[role=row]:first-of-type': {
                background: `${careSectionHeaderColor} !important`,
                textAlign: 'left',
                verticalAlign: 'baseline',

                p: {
                    fontWeight: '400',
                    verticalAlign: 'top',
                },
            },
            'div[role=row]': {
                '& .ac-container': {
                    border: `1px solid ${careSectionTableBorder}`,
                    borderBottomWidth: theme.spacing(0.5),
                    padding: 'unset !important',
                    p: {
                        fontWeight: '400',
                        padding: theme.spacing(3),
                        verticalAlign: 'top',
                    },
                },
            },
        },
        '@media(max-width: 640px)': {
            flexDirection: 'column-reverse',
            gap: '5px',
        },

        '& .ac-columnSet': {
            '& .ac-container:last-child': {
                '& .ac-textBlock': {
                    flexShrink: 0,
                    minWidth: '80px',
                    height: '20px',
                    background: FeedBackgroundColor,
                    borderRadius: '10px',
                    fontFamily: 'Equip_Regular !important',
                    fontSize: `${theme.spacing(2.5)} !important`,
                    color: `${primaryGreenColor} !important`,
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: theme.spacing(1, 2),
                    textTransform: 'uppercase',
                    fontWeight: '500 !important',

                    p: {
                        fontSize: theme.spacing(2.5),
                        fontFamily: 'Equip_Regular',
                        color: primaryGreenColor,
                        lineHeight: '13px !important',
                    },
                },
            },
        },
    },
    '.ac-pushButton': {
        border: 'none',
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
        background: hoverPaleOrange,

        div: {
            color: primaryOrange,
            fontSize: theme.spacing(3.5),
            fontFamily: 'Equip_Regular',
        },
    },
}));

export const FeedPointer = styled(Box)({
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    padding: '5px',
    background: primaryOrange,
    marginRight: '10px',
});
