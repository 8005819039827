const APP_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || '';

export const API_URLS = {
    // Users
    GET_AD_USERS: `/users`,
    GET_USER_ROLE: () => `/users/me`,
    GET_USER_FEEDS: () => `/users/me/feeds`,
    PUT_FEEDS_STATUS: (nudgeId: string) => `/users/me/feeds/${nudgeId}/mark-read`,
    POST_LOGIN_HISTORY: () => `/users/me/login-history`,
    GET_USERS_LIST: `/users/list`,

    // Nudges
    GET_MOCK_ANNOUNCEMENTS_NUDGES_LIST: `/nudges/announcements`,
    GET_NUDGE_META_DATA: () => `/nudges/me/meta-data`,
    GET_NUDGES_LISTS: (primaryEmail: string, page: number, pageSize: number) =>
        `/nudges/${primaryEmail}/announcements?page=${page}&pageSize=${pageSize}`,
    POST_NUDGES: `/nudges`,
    VALIDATE_NUDGE_NAME: `/nudges/validate`,
    GET_NUDGES_HISTORY: (nudgeId: string, page: number, pageSize: number) =>
        `/nudges/${nudgeId}/history?page=${page}&pageSize=${pageSize}`,
    EDIT_NUDGE_STATUS: (nudgeId: string) => `/nudges/${nudgeId}`,
    POST_NUDGES_LISTS: `/nudges/list`,
    GET_NUDGE_PREVIEW: (nudgeId: string) => `/nudges/${nudgeId}/details`,
    GET_ANNOUNCEMENTS_GLOBAL_PLACEHOLDER: '/nudges/announcement/globalPlaceHolders',

    // Token
    GET_SAS_TOKEN: '/token/SASToken',
    GET_SAS_TOKEN_WITH_EXP: '/token/sas-token/read',

    // KPI
    GET_KPIS_LIST: `/kpis/list`,
    GET_SELECTED_KPI_DATA: (kpiId: string) => `/kpis/${kpiId}`,

    // Docs
    KB_GET_FOLDERS: `/content-source/documenthub/contents`,
    KB_FOLDER_CREATE: (currentSourceName: string) => `content-source/${currentSourceName}/folders`,
    KB_UPLOAD: `/content-source/documenthub/files`,
    KB_FILE: (sourceName: string, documentId: string) =>
        `/content-source/${sourceName}/documents/${documentId}`,
    KB_FOLDER: `/docs/folder`,
    // common to content source
    KB_DELETE_FOLDER: (sourceName: string) => `/content-source/${sourceName}/folder`,
    KB_DISCONNECT_SOURCE: (sourceName: string) => `/content-source/${sourceName}`,
    KB_METADATA: (id: string) => `/docs/metadata/${id}`,
    KB_EDIT_LEGAL_ENTITY: (sourceName: string, documentId: string) =>
        `/content-source/${sourceName}/documents/${documentId}/legal-entities`,

    GET_CONTENT_SOURCES: '/content-source',
    UPDATE_CONTENT_SOURCE_NAME: (contentSource: string) => `/content-source/${contentSource}`,

    CONTENTSOURCE_SYNC_NOW: `${process.env.NEXT_PUBLIC_API_SYNC_URL}/api/httpSync`,
    CONTENTSOURCE_SYNC_SCHEDULE: (sourceName: string, uuid: string) =>
        `/content-source/${sourceName}/folders/${uuid}/sync-schedule`,
    GET_CONTENT_SOURCE_VIEW_DOCUMENT: (sourceName: string, documentId: string) =>
        `/content-source/${sourceName}/documents/${documentId}`,

    // SharePoint
    CONNECT_SHAREPOINT: (sourceName: string) => `/content-source/${sourceName}`,
    GET_SHAREPOINT_DOCUMENT_LIBRARIES: (sourceName: string, siteURL: string) =>
        `/content-source/${sourceName}/documentLibrary?siteURL=${siteURL}`,
    GET_SHAREPOINT_PAGES: (sourceName: string, siteURL: string, nextLink: string) =>
        `/content-source/${sourceName}/pages?siteURL=${siteURL}&nextLink=${nextLink}`,
    GET_LIBRARY_CONTENT: (sourceName: string, siteId: string, driveId: string) =>
        `/content-source/${sourceName}/libraryContent?siteId=${siteId}&driveId=${driveId}`,
    GET_SHAREPOINT_FOLDER_CONTENT: (
        sourceName: string,
        folderId: string,
        driveId: string,
        nextLink: string
    ) =>
        `/content-source/${sourceName}/folderContent?folderId=${folderId}&driveId=${driveId}&nextLink=${nextLink}`,
    SHAREPOINT_FILE_UPLOAD: (sourceName: string) => `/content-source/${sourceName}/files`,
    // Reports
    EXPORT_REPORTS_DATA: (format: string) => `/reports/export/${format}`,
    FETCH_UESR_ACTIVITY_REPORT: '/reports/activities',
    GET_USER_QUERY_LOG: (startDate: string, endDate: string) =>
        `/reports/query-analytics?startDate=${startDate}&endDate=${endDate}`,
    GET_REPORTS_DETAILS: '/reports/query-logs',
    GET_ROUTED_SERVICE_SUMMARY: '/reports/routed-service-summary',
    GET_QUESTION_DETAILS: (dialogId: string, detail: string) =>
        `/reports/dialogs/${dialogId}/details/${detail}`,
    GET_CONTENT_SOURCE_FOLDERS: (sourceName: string) => `/content-source/${sourceName}/folders`,
    GET_CONTENT_SOURCE_FOLDER_CONTENT: (
        sourceName: string,
        parentId: string,
        sortField: string,
        sortOrder: string
    ) =>
        `/content-source/${sourceName}/folders?parentId=${parentId}&sortField=${sortField}&sortOrder=${sortOrder}`,
    CREATE_CONTENT_SOURCE_FOLDERS: (sourceName: string) => `/content-source/${sourceName}/folders`,
    GET_REPORTS_SEARCH_ITEMS: (searchItemName: string, searchValueParam: string) =>
        `/reports/search-items/${searchItemName}?searchValue=${searchValueParam}`,
    GET_SYNC_HISTORY_LIST: (sourceName: string, id: string) =>
        `/content-source/${sourceName}/documents/${id}/sync-history`,

    // Download llm docs
    GET_DOWNLOADED_DOCUMENT: (id: string) => `/content-source/download/doc/${id}`,
};

export const APP_BASE_API_URL = APP_BASE_URL;
