export const MAX_FILE_SIZE = 2000000;
export const SP_MAX_FILE_SIZE = 1000000;
export const SP_MAX_FILES_LIMIT = 5;
export const VALID_FILE_EXTENSIONS = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
export const VALID_FILE_TYPES = [
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
export const DOCUMENT_FIELD = {
    FileName: 'name',
    FileStatus: 'status',
    FileSize: 'size',
    FileCreatedOn: 'createdAt',
    FileLastSyncedOn: 'lastSyncedOn',
};
export const SORTING_ORDER = {
    ASC: 'asc',
    DESC: 'desc',
};
export const FOLDER_POLL_SECS = 60;
export const PREV_SELECTED_FOLDER = 'prevSelectedFolder';
export const contentSourceType = ['ginger-document-hub', 'share-point'];
export const DOCUMENT_HUB = 'documenthub';
export const SHAREPOINT = 'sharepoint';
export const gingerDocumentHubTranslation = {
    sourceName: 'Ginger Document Hub',
    dialog: {
        title: 'kb.create.dialog.title',
        blankValidation: 'kb.create.dialog.namespace.error',
        regexValidation: 'kb.create.dialog.validation.error1',
        periodValidation: 'kb.create.dialog.validation.error2',
        inputLabel: 'kb.create.dialog.name.label',
    },
    UploadPanel: {
        title: 'kb.doc.upload.start.title',
        subTitle: 'kb.doc.upload.start.subtitle',
    },
    noFolder: {
        title: 'kb.no-folders.title',
        subTitle: 'kb.no-folders.subtitle',
        buttonLabel: 'kb.create.folder',
    },
};

export const sharePointTranslation = {
    sourceName: 'SharePoint',
    dialog: {
        title: 'kb.sharePoint.create.dialog.title',
        blankValidation: 'kb.sharePoint.create.dialog.namespace.error',
        regexValidation: 'kb.sharePoint.create.dialog.validation.error1',
        periodValidation: 'kb.sharePoint.create.dialog.validation.error2',
        inputLabel: 'kb.labels.folders',
    },
    UploadPanel: {
        title: 'kb.sharePoint.upload.start.title',
        subTitle: 'kb.sharepoint.upload.start.subtitle',
    },
    noFolder: {
        title: 'kb.no-k-repos.title',
        subTitle: 'kb.no-k-repos.subtitle',
        buttonLabel: 'kb.create',
    },
};

export const ContentActionsList = [
    { value: 'view', label: 'action.contentSource.view', icon: '' },
    { value: 'disconnect', label: 'action.contentSource.disconnect', icon: '' },
];
