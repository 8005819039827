import { Typography, useTheme } from '@mui/material';

import { white } from '@/src/styles/theme/lightThemeOptions';

interface ToolTipTitleProps {
    header: string | React.ReactNode;
    content?: React.ReactNode;
    contentHeight?: number;
}

const ToolTipTitle: React.FC<ToolTipTitleProps> = (props) => {
    const { header, content, contentHeight } = props;
    const theme = useTheme();
    return (
        <>
            {header && (
                <Typography color={white} className="header" variant="body2">
                    {header}
                </Typography>
            )}
            {content && (
                <Typography
                    variant="body2"
                    component="div"
                    sx={{ overflow: 'auto', maxHeight: theme.spacing(contentHeight || 60) }}
                    className="content"
                    data-testid="styled-typography"
                >
                    {content}
                </Typography>
            )}
        </>
    );
};
export default ToolTipTitle;
