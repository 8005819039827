import { Box, Button, styled, useMediaQuery, useTheme } from '@mui/material';
import type { GetStaticProps } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';

import { smallScreenTab } from '../shared/constants/MediaQueryConstants';
import { getBlobUrl } from '../utils/commonUtil';
// This route may be removed in future scope of mobile menus. so make styled component here only.
export const StyledMobileNotAccessBox = styled(Box)(() => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
    height: '80vh',
    img: {
        width: '100% ',
    },
}));
const NOT_ACCESS_MOBILE = () => {
    const router = useRouter();
    const isMobileScreen: boolean = useMediaQuery(smallScreenTab);

    useEffect(() => {
        if (!isMobileScreen) router.push('/404');
    }, [isMobileScreen]);

    const theme = useTheme();
    const { t } = useTranslation(['common']);

    return (
        <>
            <Button
                variant="outlined"
                sx={{
                    borderRadius: theme.spacing(1.5),
                    marginTop: theme.spacing(8),
                    marginLeft: theme.spacing(6),
                }}
                onClick={() => router.push('/')}
            >
                {t('back')}
            </Button>
            <StyledMobileNotAccessBox>
                <Image
                    src={getBlobUrl('NoAccessMobile')}
                    alt="not access in mobile view"
                    width={350}
                    height={250}
                />
            </StyledMobileNotAccessBox>
        </>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common'])),
        },
    };
};

NOT_ACCESS_MOBILE.layout = 'forbiddenOnMobile';
export default NOT_ACCESS_MOBILE;
