import { useState } from 'react';

import type { FeedApiResponse } from '@/src/shared/services/apiServices/responseModel/feedsType';

import { Feeds } from './components/Feeds';
import { Header } from './components/Header';
import { GingerCares } from './style';

interface CaresProps {
    userFeeds: FeedApiResponse[];
}
export const Cares: React.FC<CaresProps> = ({ userFeeds }) => {
    const [unreadFeedsCount, setUnreadFeedsCount] = useState<number>(0);
    return (
        <GingerCares data-testid="gingerCares">
            <Header feedsCount={unreadFeedsCount} />
            <Feeds
                userFeeds={userFeeds}
                setUnreadFeedsCount={(count) => setUnreadFeedsCount(count)}
            />
        </GingerCares>
    );
};
