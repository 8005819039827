import type { AxiosResponse } from 'axios';

import { httpClient } from '@/src/shared/services/api.services';
import { API_URLS } from '@/src/shared/services/endpoints/apiUrls';

import type {
    UpdateFeedStatusErrorResponse,
    UpdateFeedStatusSuccessResponse,
} from './responseModel/UpdateFeedsReadType';

export const updateFeedsReadStatus = async (
    nudgeId: string
): Promise<AxiosResponse<UpdateFeedStatusSuccessResponse | UpdateFeedStatusErrorResponse>> => {
    try {
        const response = await httpClient.put(API_URLS.PUT_FEEDS_STATUS(nudgeId));
        return response;
    } catch (error: any) {
        return error?.cause.data;
    }
};
