import type { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import React, { createContext, memo, useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '@/src/store';
import { userSlice } from '@/src/store/apps/user';

import { loginRequest } from '../config/authConfig';
import { allowedDomain, IdleTimeOutTiming } from '../constants/CommonConstants';
import useErrorModalHooks from '../hooks/useErrorModalHooks';
import { getProfileData } from '../services/apiServices';
import type { Permission } from '../services/apiServices/responseModel/userApiType';
import { getUserRolePermissions, updateLoginHistory } from '../services/apiServices/usersApi';
import { removeCookie } from '../services/cookie.service';

type AuthContextProps = {
    account: AuthenticationResult['account'] | null;
    accessToken: string | null;
    login: () => void;
    logout: () => void;
    getAccessToken: () => void;
    isHistory: boolean;

    permissionDataArray: Permission[];
    isPermissionApiCalled?: boolean;
};

export const AuthContext = createContext<AuthContextProps>({
    account: null,
    accessToken: null,
    login: () => {},
    logout: () => {},
    getAccessToken: () => {},
    isHistory: false,
    permissionDataArray: [],
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const user = useSelector((state: RootState) => state.user['state/user']);
    const [accessToken, setAccesstoken] = useState<string>('');
    const [isPermissionApiCalled, setIsPermissionAPiCalled] = useState<boolean>(false);
    const [permissionDataArray, setPermissionDataArray] = useState<Permission[]>([]);
    const [isHistory, setIsHistory] = useState<boolean>(false);

    const { openDialog, setResponseErrorContent, dialogComponent } = useErrorModalHooks();

    const msalLogout = (idle?: boolean, accountResponse?: any, redirectUri?: string) => {
        const accountObj = instance.getAccountByUsername(accounts[0]?.username);

        let postLogoutRedirectUri;
        if (!redirectUri) {
            if (typeof idle === 'boolean' && idle) {
                postLogoutRedirectUri = '/session-timeout';
            } else {
                postLogoutRedirectUri = '/';
            }
        } else {
            postLogoutRedirectUri = redirectUri;
        }

        if (accountObj || accountResponse) {
            instance?.logoutRedirect({
                account: accountObj || accountResponse,
                onRedirectNavigate: () => {
                    return true;
                },
                postLogoutRedirectUri,
            });
        }
    };

    const logout = async (idle?: boolean) => {
        const responseUpdateLoginHistory: any = await updateLoginHistory(user?.givenName, true);
        if (responseUpdateLoginHistory?.error || responseUpdateLoginHistory?.errors) {
            setResponseErrorContent(responseUpdateLoginHistory);
            openDialog();
        }
        localStorage.removeItem('accessToken');
        removeCookie('locale');
        dispatch(userSlice.actions.userRole({}));
        dispatch(userSlice.actions.user({}));
        msalLogout(idle);
        const logoutMessage = { type: 'logout' };
        const channel = new BroadcastChannel('msalLogoutChannel');
        channel?.postMessage(logoutMessage);
    };
    const onIdle = async () => {
        if (accounts[0]) {
            await logout(true);
        }
    };

    useIdleTimer({
        onIdle,
        timeout: parseInt(IdleTimeOutTiming, 10),
        throttle: 500,
    });

    const getAccessToken = async (): Promise<string> => {
        try {
            const response = await instance.acquireTokenSilent({
                scopes: ['User.ReadBasic.All'],
                account: accounts[0],
            });
            return response.accessToken;
        } catch (error) {
            console.warn('Silent token acquisition failed, falling back to redirect...', error);
            await instance.acquireTokenRedirect({
                scopes: ['User.ReadBasic.All'],
            });
            // The token will be captured after the redirect via handleRedirectPromise
            return ''; // Placeholder; the redirect flow doesn't return the token here
        }
    };

    // Handle the redirect response globally
    useEffect(() => {
        instance
            .handleRedirectPromise()
            .then((response) => {
                if (response) {
                    const token = response.accessToken;
                    console.log('Token acquired after redirect:', token);
                }
            })
            .catch((error) => {
                console.error('Error handling redirect promise:', error);
            });
    }, []);

    useEffect(() => {
        const setToken = async () => {
            instance.setActiveAccount(accounts[0]);
            const token = (await getAccessToken()) || '';
            setAccesstoken(token);
            if (token) {
                const userRoledata: any = await getUserRolePermissions();
                setIsPermissionAPiCalled(true);
                if (userRoledata?.error || userRoledata?.errors) {
                    setResponseErrorContent(userRoledata);
                    openDialog();
                } else {
                    const permissionArray: Permission[] =
                        userRoledata?.data.data?.permissions || [];

                    sessionStorage.setItem('permissionDataArray', JSON.stringify(permissionArray));
                    setPermissionDataArray(permissionArray);

                    const userProfileData = await getProfileData();
                    if (userProfileData) {
                        dispatch(userSlice.actions.user(userProfileData));
                    }
                    dispatch(userSlice.actions.userRole(userRoledata?.data));
                    setIsHistory(true);
                }
            }
        };
        if (!accessToken && accounts[0]) {
            setToken();
        }
    }, []);

    useEffect(() => {
        instance.handleRedirectPromise().then(async (response) => {
            if (response) {
                const token = response?.accessToken || '';
                setAccesstoken(token);

                if (token) {
                    const { account } = response;
                    const email = account?.username;
                    if (!email?.endsWith(allowedDomain)) {
                        msalLogout(false, account, '/unknown-domain');
                    }

                    const userRoledata: any = await getUserRolePermissions();
                    if (userRoledata?.error || userRoledata?.errors) {
                        setResponseErrorContent(userRoledata);
                        openDialog();
                    } else {
                        dispatch(userSlice.actions.userRole(userRoledata?.data));
                    }
                    dispatch(userSlice.actions.userRole(userRoledata.data));
                    const userProfileData: any = await getProfileData();
                    if (userProfileData) {
                        dispatch(userSlice.actions.user(userProfileData));
                    }

                    const responseUpdateLoginHistory: any = await updateLoginHistory(
                        userProfileData?.givenName || '',
                        false
                    );
                    if (responseUpdateLoginHistory?.error || responseUpdateLoginHistory?.errors) {
                        setResponseErrorContent(responseUpdateLoginHistory);
                        openDialog();
                    } else if (responseUpdateLoginHistory?.code === 'OK') {
                        setIsHistory(true);
                        instance.setActiveAccount(accounts[0]);
                    }
                }
            }
        });
    }, []);

    function handleLogoutBroadcast(event: any) {
        if (event.data.type === 'logout') {
            setTimeout(() => {
                const accountsData = instance.getAllAccounts();
                if (accountsData.length > 0) {
                    handleLogoutBroadcast(event);
                } else {
                    instance?.logout();
                }
            }, 500);
        }
    }

    useEffect(() => {
        const channel = new BroadcastChannel('msalLogoutChannel');
        channel?.addEventListener('message', handleLogoutBroadcast);

        return () => {
            channel.removeEventListener('message', handleLogoutBroadcast);
            channel?.close();
        };
    }, []);
    const login = async () => {
        try {
            instance.loginRedirect({ ...loginRequest });
        } catch (error) {
            console.error(error);
        }
    };

    const memoedValue = useMemo(
        () => ({
            account: accounts[0],
            accessToken,
            login,
            logout,
            getAccessToken,
            isHistory,
            permissionDataArray,
            isPermissionApiCalled,
        }),
        [accessToken, accounts[0], login]
    );

    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
            {dialogComponent}
        </AuthContext.Provider>
    );
};

export const MemoAuthProvider = memo(AuthProvider);
