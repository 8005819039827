import { createSlice } from '@reduxjs/toolkit';

import { applyStateupdate } from '../utils';

const metaObj = {
    userGroups: [],
    legalEntities: [],
    channels: [],
};
export const initState = {
    'state/legalEntity': '',
    'state/requestedBy': [],
    'state/legalEntities': [],
    'state/SelectedUsers': [],
    'state/SelectedUsersName': '',
    'state/SelectedlegalEntities': [],
    'state/roleManagementMetaData': metaObj,
    'state/isBackToUsersClicked': false,
    'state/SelectedUser': [],
};

export const roleManagementSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        legalEntities: (state, action) => {
            return applyStateupdate(state, action);
        },
        legalEntity: (state, action) => {
            return applyStateupdate(state, action);
        },
        requestedBy: (state, action) => {
            return applyStateupdate(state, action);
        },
        SelectedUsers: (state, action) => {
            return applyStateupdate(state, action);
        },
        SelectedUser: (state, action) => {
            return applyStateupdate(state, action);
        },
        SelectedUsersName: (state, action) => {
            return applyStateupdate(state, action);
        },
        SelectedlegalEntities: (state, action) => {
            return applyStateupdate(state, action);
        },
        roleManagementMetaData: (state, action) => {
            return applyStateupdate(state, action);
        },
        isBackToUserRoleClicked: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return initState;
        },
    },
});

export default roleManagementSlice;
