import { Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';

import { greyColor, white } from '@/src/styles/theme/lightThemeOptions';

export const containerHeight = 'calc(100vh - 150px)';
interface StyledBoxProps {
    isadmin: string;
}

const stngToBool = (str: string) => str === 'true';

export const AppHeaderContainer = styled(Box)<StyledBoxProps>(({ theme, isadmin }) => ({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    height: stngToBool(isadmin) ? '62px' : '90px',
    padding: theme.spacing(7.5, 5, 0, 0),
    alignItems: stngToBool(isadmin) ? 'flex-end' : 'flex-start',
    '@media(max-width: 1024px)': {
        padding: stngToBool(isadmin) ? theme.spacing(0) : theme.spacing(4, 4, 0, 4),
        height: theme.spacing(16),
    },
    '@media(min-width: 1025px)': {
        padding: !stngToBool(isadmin) ? theme.spacing(7.5, 15, 0) : theme.spacing(7.5, 5, 0, 0),
    },
    '@media(max-width: 640px)': {
        padding: theme.spacing(5, 5, 0),
        height: '50px',
        alignItems: 'center',
    },
    '& .ginger-logo': {
        marginBottom: theme.spacing(1),
    },
}));

export const AppMainContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    maxHeight: '100%',
    position: 'relative',
    padding: '0px 60px',
    width: '100%',
    '@media(max-width: 640px)': {
        padding: theme.spacing(0, 5),
        '.ac-actionSet': {
            flexDirection: 'column !important',
            gap: theme.spacing(1),
        },
    },
    '@media(min-width: 992px)': {
        minWidth: '60%',
    },
    '@media(max-width: 1024px)': {
        padding: theme.spacing(0),
    },
    '@media(min-width: 1200px)': {
        width: '100%',
    },
}));

export const GingerToolbar = styled(Grid)(({ theme }) => ({
    marginTop: '0px',
    height: '100%',
    flexWrap: 'unset',
    '@media(max-width:1024px)': {
        '&.home_page': {
            padding: theme.spacing(4),
            marginLeft: theme.spacing(0),
            width: '100%',
        },
        '& .grid-care': {
            minWidth: '50% !important',
            paddingLeft: theme.spacing(0),
        },
    },
    '@media(max-width: 767px)': {
        width: '100%',
        marginLeft: '0',
        '& .grid-care': {
            paddingLeft: '0',
        },
    },

    '& .AskContainerParent': {
        position: 'relative',
        paddingTop: theme.spacing(3.5),
        paddingLeft: theme.spacing(8),
        width: '100%',
        maxWidth: '100%',
        '& .footer ': {
            display: 'flex',
            paddingInlineStart: 0,
        },
        '@media(max-width:1024px)': {
            paddingLeft: theme.spacing(8),
        },
        '@media(max-width: 820px)': {
            '& .footer ': {
                paddingInlineStart: theme.spacing(0),
                span: {
                    paddingInline: theme.spacing(1),
                },
            },
        },
    },
    '& .AskContainerParent.askDisplay': {
        position: 'static',
        width: '100%',
        paddingLeft: theme.spacing(2),
        '@media(max-width: 820px)': {
            right: 0,
        },
        '@media(max-width: 767px)': {
            display: 'block',
            margin: '0 auto',
            width: '100%',
            paddingLeft: '0',
        },
    },
    '& .grid-care': {
        paddingTop: theme.spacing(3.5),
        '@media(min-width: 768px)': {
            minWidth: '59%',
        },
    },
}));

export const ContainerWrapper = styled(Grid)({
    marginTop: '0px',
    flexWrap: 'unset',
});

export const Wrapper = styled(Paper)({
    background: `${white}`,
    borderRadius: '10px',
    mozFlexDirection: 'column',
    flexDirection: 'column',
    width: '100%',
    minHeight: containerHeight,
    height: containerHeight,
    borderTopLeftRadius: 0,
});

export const StyledHeader = styled('span')(({ theme }) => ({
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    background: greyColor,
    textTransform: 'uppercase',
    color: white,
    padding: '0 4px',
    fontSize: theme.spacing(3),
    position: 'absolute',
    top: 0,
}));
