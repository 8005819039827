import { useMsal } from '@azure/msal-react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import UnauthorisedPage from '@/src/pages/401';
import ForbiddenPage from '@/src/pages/403';
import HealthCheckPage from '@/src/pages/health-check';
import NOT_ACCESS_MOBILE from '@/src/pages/not-access-mobile';
import SessionTimeOut from '@/src/pages/session-timeout';
import UnknownDomain from '@/src/pages/unknown-domain';
import { AuthContext } from '@/src/shared/contexts/AzureAuthProvider';
import type { Permission } from '@/src/shared/services/apiServices/responseModel/userApiType';
import { canAccessRoutes } from '@/src/utils/commonUtil';

import Custom404 from '../../pages/404';
import {
    adminLayout,
    defaultlayout,
    forbiddenOnMobile,
    forbiddenPage,
    HealthCheck,
    sessionTimeOut,
    unAuthorised,
    unknownDomain,
} from '../../shared/constants/layoutConstant';
import AdminLayout from './adminlayout/AdminLayout';
import DefaultLayout from './defaultlayout/DefaultLayout';

const Layout = (props: {
    layoutType: string;
    children: ReactNode;
    permissionDataArray?: Permission[];
    setIsAccessRoutes?: () => void;
}) => {
    const { accounts } = useMsal();
    const [isAccessRoutes, setIsAccessRoutes] = useState<boolean>(false);
    // eslint-disable-next-line prefer-const
    let { permissionDataArray, isPermissionApiCalled } = React.useContext(AuthContext);
    if (permissionDataArray.length === 0 && typeof window !== 'undefined') {
        const storedData = sessionStorage.getItem('permissionDataArray');

        const data: Permission[] = storedData ? JSON.parse(storedData) : [];
        permissionDataArray = data;
    }

    const router = useRouter();
    const lastIndex = router?.pathname?.lastIndexOf('[');
    const basePathName =
        lastIndex !== -1 ? router?.pathname?.substring(0, lastIndex - 1) : router?.pathname;
    useEffect(() => {
        const is404Page = router.pathname.includes('/404');
        const isNewsLetterPage = router.pathname.includes('/things-you-must-know');
        const healthCheck = router.pathname.includes('/health-check');
        const isAuthenticateRoute: boolean =
            canAccessRoutes(permissionDataArray, basePathName) || basePathName === '/';
        setIsAccessRoutes(!accounts[0] || isAuthenticateRoute);
        if (
            !isAuthenticateRoute &&
            !is404Page &&
            !isNewsLetterPage &&
            !healthCheck &&
            isPermissionApiCalled
        ) {
            router.replace('/403');
        }
    }, [isPermissionApiCalled]);
    return (
        <>
            {props.layoutType === adminLayout && (
                <>
                    <AdminLayout isAccessRoutes={isAccessRoutes}>{props?.children}</AdminLayout>
                </>
            )}
            {props.layoutType === defaultlayout && (
                <>
                    <DefaultLayout>{props?.children}</DefaultLayout>
                </>
            )}
            {props.layoutType === unAuthorised && (
                <>
                    <UnauthorisedPage />
                </>
            )}
            {props.layoutType === forbiddenPage && (
                <>
                    <ForbiddenPage />
                </>
            )}
            {props.layoutType === sessionTimeOut && (
                <>
                    <SessionTimeOut />
                </>
            )}
            {props.layoutType === unknownDomain && (
                <>
                    <UnknownDomain />
                </>
            )}
            {props.layoutType === forbiddenOnMobile && (
                <>
                    <DefaultLayout>
                        <NOT_ACCESS_MOBILE />
                    </DefaultLayout>
                </>
            )}
            {!props.layoutType && props.layoutType !== defaultlayout && (
                <>
                    <Custom404 />
                </>
            )}
            {props.layoutType === HealthCheck && (
                <>
                    <HealthCheckPage />
                </>
            )}
        </>
    );
};
export default Layout;
