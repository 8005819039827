import { createSlice } from '@reduxjs/toolkit';

import { applyStateupdate } from '../utils';

interface UserState {
    'state/user': object;
    'state/userRole': object;
    'state/isAdminOnlyBgShow': boolean;
    'state/isadmin': boolean;
    'state/isConversationErrBoundary': boolean;
}

export const initState: UserState = {
    'state/user': {},
    'state/userRole': {},
    'state/isAdminOnlyBgShow': false,
    'state/isadmin': false,
    'state/isConversationErrBoundary': false,
};

export const userSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        user: (state, action) => {
            return applyStateupdate(state, action);
        },
        userRole: (state, action) => {
            const newState = applyStateupdate(state, action);
            newState['state/isadmin'] = newState['state/userRole']?.data?.roles.some(
                (role: { name: string }) => role.name === 'Admin'
            );
            return newState;
        },
        isAdminOnlyBgShow: (state, action) => {
            return applyStateupdate(state, action);
        },
        isConversationErrBoundary: (state, action) => {
            return applyStateupdate(state, action);
        },
    },
});

export default userSlice;
