import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { Box, Button, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import type { SVGProps } from 'react';

import { alertRed, darkGrey, labelColor } from '@/src/styles/theme/lightThemeOptions';

import CustomizedTooltip from '../../Tooltip/HTMLTooltip';
import ToolTipTitle from '../../Tooltip/ToolTipTitle';
import { BootstrapDialog } from './CancelDialog.style';

interface ButtonProps {
    label: string;
    variant?: 'outlined' | 'contained' | 'text';
    handleClick: () => void;
    disabled?: boolean;
    lodingIcon?: SVGProps<SVGSVGElement> | React.ReactNode;
    tooltipTitle?: string | React.ReactNode;
}

interface CustomizedDialogsProps {
    title: string;
    dialogContent: string | React.ReactNode;
    open: boolean;
    handleClose: () => void;
    buttons?: ButtonProps[];
    isErrorIconShow?: boolean;
    isButtonLoader?: boolean;
    restProps?: any;
}

const CancelDialog = ({
    title,
    dialogContent,
    open,
    handleClose,
    buttons,
    isErrorIconShow = false,
    isButtonLoader = false,
    restProps,
}: CustomizedDialogsProps) => {
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                data-testid="cancel-dialog"
                {...restProps}
            >
                <DialogContent>
                    <Box
                        style={{
                            display: 'flex',
                            padding: '13px 15px',
                            background: darkGrey,
                            gap: '20px',
                            justifyContent: 'space-between',
                        }}
                    >
                        <DialogTitle style={{ padding: '0' }}>{title}</DialogTitle>
                        <HighlightOffIcon
                            onClick={handleClose}
                            className="closeIcon"
                            data-testid="close-icon"
                        />
                    </Box>
                    <Grid container direction="column">
                        <Grid item xs={8} className="textContainer">
                            <Typography color={labelColor} className="dialog-content">
                                {isErrorIconShow && (
                                    <ReportProblemRoundedIcon
                                        sx={{ color: alertRed }}
                                        className="alertIcon"
                                    />
                                )}
                                {dialogContent}
                            </Typography>
                            <Divider />
                            <Grid direction="row" className="buttonsWrapper">
                                {buttons?.map((button, i) => {
                                    return (
                                        <CustomizedTooltip
                                            key={i}
                                            arrow
                                            placement="top"
                                            title={
                                                button?.tooltipTitle ? (
                                                    <ToolTipTitle header={button.tooltipTitle} />
                                                ) : undefined
                                            }
                                            sx={{
                                                '& .MuiTooltip-tooltip': {
                                                    width: '200px',
                                                    marginBottom: '4px !important',
                                                    p: {
                                                        width: '100%',
                                                    },
                                                },
                                            }}
                                        >
                                            <Button
                                                key={`button_${button.label}`}
                                                variant={button.variant}
                                                onClick={button.handleClick}
                                                disabled={button.disabled}
                                                data-testid={`button_${button.label}`}
                                            >
                                                <>
                                                    {isButtonLoader && button?.lodingIcon}
                                                    {button.label}
                                                </>
                                            </Button>
                                        </CustomizedTooltip>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
};

export default CancelDialog;
