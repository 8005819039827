import { useMsal } from '@azure/msal-react';
import { Box, styled, useTheme } from '@mui/material';
import type { FC, ReactNode } from 'react';

import { Header } from '../../Header';
import { AppBar } from '../adminlayout/adminLayout.style';
import { AppMainContainer } from '../style';

interface StyledBoxProps {
    isloading: string;
}
const DefaultLayout: FC<{ children: ReactNode }> = ({ children }) => {
    const { accounts } = useMsal();
    const stngToBool = (str: string) => str === 'true';

    const BgOverlayAppWrapper = styled(Box)<StyledBoxProps>(({ isloading }) => ({
        background: !stngToBool(isloading) ? 'rgba(0,0,0,0.5)' : 'none',
        zIndex: !stngToBool(isloading) ? '2' : '1',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    }));

    const theme = useTheme();
    return (
        <BgOverlayAppWrapper
            data-testid="default-layout"
            isloading={(!!accounts[0] || false).toString()}
        >
            <AppBar position="static" theme={theme} className="homePageHeader">
                <Header />
            </AppBar>
            <AppMainContainer>{children}</AppMainContainer>
        </BgOverlayAppWrapper>
    );
};

export default DefaultLayout;
