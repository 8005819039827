import type { AuthenticationResult } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

import { customAPITokenRequest, loginRequest, msalConfig } from '../shared/config/authConfig';

const GetMSALResponse = async () => {
    const msalInstance = new PublicClientApplication(msalConfig());
    const accounts = msalInstance.getAllAccounts();

    const account = accounts[0]; // Replace with your logic to select the specific account

    const response: AuthenticationResult = await msalInstance.acquireTokenSilent({
        ...loginRequest,

        account,
    });

    const customTokenResponse: AuthenticationResult = await msalInstance.acquireTokenSilent({
        ...customAPITokenRequest,

        account,
    });

    return { loginRequestToken: response, customRequestToken: customTokenResponse };
};

export default GetMSALResponse;
