import { createSlice } from '@reduxjs/toolkit';

import { SEND_NOW } from '@/src/shared/constants/CreateNudgeConstants';
import { Days, FrequencyArr, weekArr } from '@/src/shared/constants/SceduleNudgeConstants';

import { applyStateupdate } from '../../utils';

interface NudgeScheduleState {
    'state/scheduleType': string;
    'state/reScheduleNudgeName': string;
    'state/isRecurring': boolean;
    'state/publishDate': object | null;
    'state/publishDateError': boolean;
    'state/publishTime': object | null;
    'state/publishTimeError': boolean;
    'state/frequency': string;
    'state/startDate': object | null;
    'state/startTime': object | null;
    'state/startTimeError': boolean;
    'state/endDate': object | null;
    'state/endTime': object | null;
    'state/occurence': number | string;
    'state/selectedWeekDay': number | null;
    'state/recurrence': 'end date' | 'occurence' | 'no end date' | null;
    'state/monthly_weekDayNo': number;
    'state/monthly_weekDay': number;
    'state/isEditNudge': boolean;
    'state/isReschedulingEditNudge': boolean;
}

export const initState: NudgeScheduleState = {
    'state/scheduleType': SEND_NOW,
    'state/reScheduleNudgeName': '',
    'state/isRecurring': false,
    'state/publishDate': null,
    'state/publishDateError': false,
    'state/publishTime': null,
    'state/publishTimeError': false,
    'state/frequency': FrequencyArr[0].value as string,
    'state/startDate': null,
    'state/startTime': null,
    'state/startTimeError': false,
    'state/endDate': null,
    'state/endTime': null,
    'state/occurence': '',
    'state/selectedWeekDay': null,
    'state/recurrence': null,
    'state/monthly_weekDayNo': Days[0].value as number,
    'state/monthly_weekDay': weekArr[0].value as number,
    'state/isEditNudge': false,
    'state/isReschedulingEditNudge': false,
};

export const nudgeScheduleSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        scheduleType: (state, action) => {
            return applyStateupdate(state, action);
        },
        reScheduleNudgeName: (state, action) => {
            return applyStateupdate(state, action);
        },
        isRecurring: (state, action) => {
            return applyStateupdate(state, action);
        },
        publishDate: (state, action) => {
            return applyStateupdate(state, action);
        },
        publishDateError: (state, action) => {
            return applyStateupdate(state, action);
        },
        publishTime: (state, action) => {
            return applyStateupdate(state, action);
        },
        publishTimeError: (state, action) => {
            return applyStateupdate(state, action);
        },
        frequency: (state, action) => {
            return applyStateupdate(state, action);
        },
        startDate: (state, action) => {
            return applyStateupdate(state, action);
        },
        endDate: (state, action) => {
            return applyStateupdate(state, action);
        },
        endTime: (state, action) => {
            return applyStateupdate(state, action);
        },
        startTime: (state, action) => {
            return applyStateupdate(state, action);
        },
        startTimeError: (state, action) => {
            return applyStateupdate(state, action);
        },
        occurence: (state, action) => {
            return applyStateupdate(state, action);
        },
        recurrence: (state, action) => {
            return applyStateupdate(state, action);
        },
        selectedWeekDay: (state, action) => {
            return applyStateupdate(state, action);
        },
        monthly_weekDayNo: (state, action) => {
            return applyStateupdate(state, action);
        },
        monthly_weekDay: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return initState;
        },
        isEditNudge: (state, action) => {
            return applyStateupdate(state, action);
        },
        isReschedulingEditNudge: (state, action) => {
            return applyStateupdate(state, action);
        },
        updateScheduleInfo: (state, action) => {
            const updatedInfo = action.payload;
            return {
                ...state,
                ...updatedInfo,
            };
        },
        resetStatesOnKPIchange: (state) => {
            return {
                ...state,
                ...initState,
            };
        },
        resetScheduleState: (state) => {
            return {
                ...initState,
                ...{
                    'state/scheduleType': state['state/scheduleType'],
                    'state/isRecurring': state['state/isRecurring'],
                },
            };
        },
    },
});

export default nudgeScheduleSlice;
