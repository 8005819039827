import type { AxiosPromise, AxiosResponse } from 'axios';

import { httpClient } from '@/src/shared/services/api.services';
import { API_URLS } from '@/src/shared/services/endpoints/apiUrls';

import type { UserRoleType } from './responseModel';
import type { UserLoginHistoryResponse } from './responseModel/userApiType';

export const updateLoginHistory = async (
    firstName: string,
    isLogout: boolean
): Promise<AxiosResponse<UserLoginHistoryResponse>> => {
    try {
        const response = await httpClient.post(API_URLS.POST_LOGIN_HISTORY(), {
            firstName,
            isLogout,
        });
        return response?.data;
    } catch (error: any) {
        return error?.cause?.data;
    }
};

export const getUserRolePermissions = async (): Promise<AxiosPromise<UserRoleType>> => {
    try {
        return await httpClient.get(API_URLS.GET_USER_ROLE());
    } catch (error: any) {
        return error?.cause?.data;
    }
};
