/* eslint-disable @typescript-eslint/no-shadow */
import { createSlice } from '@reduxjs/toolkit';

import { chipStatus, filterType, ROW_PER_PAGE } from '@/src/shared/constants/ListTableConstants';

export type FilterType = {
    type: string;
    value: string;
};
interface CreateNudgeState {
    'state/pageType': string;
    'state/rowsPerPage': number;
    'state/page': number;
    'state/filter': FilterType[];
    'state/selectedFilter': FilterType[];
}

export const initState: CreateNudgeState = {
    'state/pageType': '',
    'state/rowsPerPage': ROW_PER_PAGE,
    'state/page': 1,
    'state/filter': [{ type: filterType.nudgeStatus, value: chipStatus.all }],
    'state/selectedFilter': [{ type: filterType.nudgeStatus, value: chipStatus.all }],
};
export const createNudgeSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        preserveFilteredValue: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                ...payload,
            };
        },
        setSelectedFilter: (state, action) => {
            const { item, chipStatus, filterType } = action.payload;

            if (state['state/selectedFilter'].some((obj: FilterType) => obj.value === item)) {
                if (state['state/selectedFilter'].length === 2) {
                    state['state/selectedFilter'] = state['state/selectedFilter'].filter(
                        (chips: FilterType) => chips.value !== item
                    );
                }
            } else if (
                state['state/selectedFilter'].length === 1 &&
                state['state/selectedFilter'][0].value !== chipStatus.all &&
                item === chipStatus.all
            ) {
                state['state/selectedFilter'] = [
                    { type: filterType.nudgeStatus, value: chipStatus.all },
                ];
            } else if (
                state['state/selectedFilter'].length === 1 &&
                state['state/selectedFilter'][0].value === chipStatus.all
            ) {
                state['state/selectedFilter'] = [{ type: filterType.nudgeStatus, value: item }];
            } else if (
                (state['state/selectedFilter'].some(
                    (obj: FilterType) => obj.value === chipStatus.published
                ) &&
                    state['state/selectedFilter'].some(
                        (obj: FilterType) => obj.value === chipStatus.unpublished
                    )) ||
                (state['state/selectedFilter'].some(
                    (obj: FilterType) => obj.value === chipStatus.unpublished
                ) &&
                    state['state/selectedFilter'].some(
                        (obj: FilterType) => obj.value === chipStatus.archived
                    )) ||
                (state['state/selectedFilter'].some(
                    (obj: FilterType) => obj.value === chipStatus.published
                ) &&
                    state['state/selectedFilter'].some(
                        (obj: FilterType) => obj.value === chipStatus.archived
                    ))
            ) {
                state['state/selectedFilter'] = [
                    { type: filterType.nudgeStatus, value: chipStatus.all },
                ];
            } else {
                state['state/selectedFilter'].push({ type: filterType.nudgeStatus, value: item });
            }
        },
        resetFilters: () => {
            return initState;
        },
    },
});

export default createNudgeSlice;
