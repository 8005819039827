import { Box, Grid } from '@mui/material';

import SkeletonWrapper from './skeletonWrapper';

export const SkeletonSlice = () => {
    return (
        <Grid
            display={'flex'}
            flexDirection={'row'}
            gap={'20px'}
            width="100%"
            data-testid="Skeleton-Slice"
        >
            <SkeletonWrapper variant="circular" width={10} />
            <Grid display={'flex'} flexDirection={'column'} gap={'20px'} width="100%">
                <SkeletonWrapper width="100%" height={20} />
                <Grid display={'flex'} flexDirection={'column'} gap={'10px'} width="100%">
                    <SkeletonWrapper width="70%" />
                    <SkeletonWrapper width="100%" />
                    <SkeletonWrapper width="60%" />
                    <SkeletonWrapper width="80%" />
                </Grid>
            </Grid>
        </Grid>
    );
};
const GingerCareSkeleton = () => {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={10}
            padding={'20px'}
            data-testid="Ginger-Care-Skeleton"
        >
            {SkeletonSlice()}
            {SkeletonSlice()}
            {SkeletonSlice()}
        </Box>
    );
};

export default GingerCareSkeleton;
