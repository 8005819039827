import type { AxiosResponse } from 'axios';

import { apiInstance } from '@/src/shared/services/api.services';

import { GRAPH_API_URLS } from '../endpoints/externalApiUrls';
import type { GraphMeResponse } from './responseModel/graphApisType';

export const getProfileData = async (): Promise<AxiosResponse<GraphMeResponse>> => {
    try {
        const response = await apiInstance.get(GRAPH_API_URLS.graphMeEndpoint);
        return response.data;
    } catch (error: any) {
        return error?.cause.data;
    }
};
