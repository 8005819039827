export const ParameterTypes = {
    number: 'number',
    date: 'date',
    select: 'enum',
    text: 'string',
};

export const RefreshTokenTimeLimit = 5 * 60 * 1000;

export const UserTypes = {
    admin: 'Admin',
    power_user: 'Power User',
};

const SESSION_TIMEOUT: string | undefined = process.env.NEXT_PUBLIC_SESSION_TIMEOUT;

const IdleTimeOutTiming = SESSION_TIMEOUT || 'default_value'; // Provide a default value here

const internalServerMsg = 'Internal Server Error';
export const QnALabels: any = [
    'entity',
    'department',
    'topic',
    'system_metadata_qna_edited_manually',
];
const FileExtensionConstants = {
    svg: 'svg',
    jpg: 'jpg',
    jpeg: 'jpeg',
    png: 'png',
};
const LogoConstants = {
    companyLogo: 'companyLogo',
    logo: 'logo',
};
// eslint-disable-next-line simple-import-sort/exports
export { FileExtensionConstants, IdleTimeOutTiming, LogoConstants, internalServerMsg };

export const timeFrameRange = [
    { id: 1, label: 'last_seven_days', value: '7' },
    { id: 2, label: 'last_one_month', value: '30' },
    { id: 3, label: 'last_three_months', value: '90' },
    { id: 4, label: 'last_one_year', value: '365' },
    { id: 5, label: 'custom', value: '0' },
];

export const DOCUMENT_LIBRARIES = 'document libraries';
export const LISTS = 'lists';
export const PAGES = 'pages';
export const FILETYPE = 'document'; // for default value of usestate
export const appVersion = process.env.NEXT_PUBLIC_APP_VERSION;

export const sharepointModuleBrowsingList = [
    {
        name: 'document_libraries',
        icon: 'folder_special',
        constName: DOCUMENT_LIBRARIES,
        type: 'document',
        enable: true,
    },
    { name: 'lists', icon: 'list_alt', constName: LISTS, type: 'list', enable: false },
    { name: 'pages', icon: 'description', constName: PAGES, type: 'page', enable: true },
];
export const directLineChatLocale = 'en-US';

export const FooterConstants = {
    newsletter: 'newsletter',
    privacyPolicy: 'privacy-policy',
};

export const allowedDomain = 'asfinag.at';
export const docViewer = 'google';
export const failureObj = { status: 'fail', code: 500 };
export const dateFormat = 'DD MMM. YYYY';
