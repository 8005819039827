import { useTranslation } from 'next-i18next';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { unCaughtConversionEndMsg } from '@/src/shared/constants/layoutConstant';
import { userSlice } from '@/src/store/apps/user';

interface Props {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

const useErrorBoundary = (): [
    ErrorBoundaryState,
    React.Dispatch<React.SetStateAction<boolean>>
] => {
    const [hasError, setHasError] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        const handleError = (error: any) => {
            if (error.message === unCaughtConversionEndMsg) {
                dispatch(userSlice.actions.isConversationErrBoundary(true));
            } else {
                setHasError(true);
            }
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);

    return [{ hasError }, setHasError];
};

const ErrorBoundary: React.FC<Props> = ({ children }) => {
    const [{ hasError }] = useErrorBoundary();
    const { t } = useTranslation(['common']);
    if (hasError) {
        return <h1>{t('app.error.boundary')}</h1>;
    }

    return <>{children}</>;
};

const ErrorBoundaryWrapper: React.FC<Props> = ({ children }) => {
    return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;
