import type { AxiosResponse } from 'axios';

import { httpClient } from '@/src/shared/services/api.services';
import { API_URLS } from '@/src/shared/services/endpoints/apiUrls';

import type { FeedApiResponse } from './responseModel/feedsType';

export const getFeeds = async (): Promise<AxiosResponse<FeedApiResponse[]>> => {
    try {
        const response = await httpClient.get(API_URLS.GET_USER_FEEDS());
        return response.data;
    } catch (error: any) {
        return error?.cause?.data;
    }
};
