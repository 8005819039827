import React from 'react';

import { FileExtensionConstants } from '@/src/shared/constants/CommonConstants';

const MenuIconConstants = {
    announcements: 'announcements',
    avatarDesigner: 'avatarDesigner',
    content: 'content',
    insights: 'insights',
    knowledgeBase: 'knowledgeBase',
    kpiRegistry: 'kpiRegistry',
    manage: 'manage',
    nudgeDesigner: 'nudgeDesigner',
    qNa: 'qNa',
    report: 'report',
    roleManagement: 'roleManagement',
    smallTalkDesigner: 'smallTalkDesigner',
    train: 'train',
    export_report: 'export-report',
    filter_report: 'filter-report',
};
const storageAccountName = process.env.NEXT_PUBLIC_STORAGE_ACCOUNT_NAME;

const GetIconFromBlob = (fileName: string, fileExtension: string = FileExtensionConstants.svg) => {
    const containerName = `gingerbot/org/icons`;
    const imageUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}.${fileExtension}`;

    const IconFromBlob: React.FC = () => {
        return <img src={imageUrl} alt={fileName} className="menu-icon-class" />;
    };
    return IconFromBlob;
};

const AnnouncementIcon = GetIconFromBlob(MenuIconConstants.announcements);
const ContentIcon = GetIconFromBlob(MenuIconConstants.content);
const InsightsIcon = GetIconFromBlob(MenuIconConstants.insights);
const KnowledgeBaseIcon = GetIconFromBlob(MenuIconConstants.knowledgeBase);
const KpiRegistryIcon = GetIconFromBlob(MenuIconConstants.kpiRegistry);
const NudgeDesignerIcon = GetIconFromBlob(MenuIconConstants.nudgeDesigner);
const QNaIcon = GetIconFromBlob(MenuIconConstants.qNa);
const SmallTalkDesignerIcon = GetIconFromBlob(MenuIconConstants.smallTalkDesigner);
const AvatarDesignerIcon = GetIconFromBlob(MenuIconConstants.avatarDesigner);
const RoleManagementIcon = GetIconFromBlob(MenuIconConstants.roleManagement);
const ReportExportIcon = GetIconFromBlob(MenuIconConstants.export_report);
const ReportFilterIcon = GetIconFromBlob(MenuIconConstants.filter_report);

export {
    AnnouncementIcon,
    AvatarDesignerIcon,
    ContentIcon,
    InsightsIcon,
    KnowledgeBaseIcon,
    KpiRegistryIcon,
    MenuIconConstants,
    NudgeDesignerIcon,
    QNaIcon,
    ReportExportIcon,
    ReportFilterIcon,
    RoleManagementIcon,
    SmallTalkDesignerIcon,
};
