import { createSlice } from '@reduxjs/toolkit';

import { applyStateupdate } from '../../utils';

interface NudgePublishState {
    'state/publishNudgeName': string;
    'state/isPublishClicked': boolean;
}

export const initState: NudgePublishState = {
    'state/publishNudgeName': '',
    'state/isPublishClicked': false,
};

export const nudgePublishSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        publishNudgeName: (state, action) => {
            return applyStateupdate(state, action);
        },
        isPublishClicked: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return initState;
        },
    },
});

export default nudgePublishSlice;
