import { createSlice } from '@reduxjs/toolkit';

import type { UserGroups } from '@/src/components/ListTable/type/ListTableType';
import type { LegalEntity } from '@/src/shared/services/apiServices/responseModel/kbFolderListType';
import type { KPIListData } from '@/src/shared/services/apiServices/responseModel/kpiListType';
import type {
    ChannelType,
    NudgeMetaDataResponse,
} from '@/src/shared/services/apiServices/responseModel/nudgesMetaData';

import { applyStateupdate } from '../../utils';

export interface RequestedByInterface {
    iconUrl: string;
    legalEntity: string;
    id: number;
    name: string;
    email: string;
    profileImage: string;
}

const initObj: RequestedByInterface = {
    iconUrl: '',
    legalEntity: '',
    id: 0,
    name: '',
    email: '',
    profileImage: '',
};
const metaObj = {
    userGroups: [],
    legalEntities: [],
    channels: [],
};
interface NudgeConfigureState {
    'state/requestedBy': RequestedByInterface;
    'state/nudgeName': string;
    'state/nudgeNameDuplicateError': boolean;
    'state/legalEntity': LegalEntity[];
    'state/userGroup': UserGroups[];
    'state/channel': ChannelType[];
    'state/configureMetaData': NudgeMetaDataResponse;
    'state/userFilter': boolean;
    'state/requestedByName': string;
    'state/kpiList': KPIListData[];
}

export const initState: NudgeConfigureState = {
    'state/requestedBy': initObj,
    'state/requestedByName': '',
    'state/nudgeName': '',
    'state/nudgeNameDuplicateError': false,
    'state/legalEntity': [],
    'state/userGroup': [],
    'state/channel': [],
    'state/configureMetaData': metaObj,
    'state/userFilter': false,
    'state/kpiList': [],
};
export const nudgeConfigureSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        requestedBy: (state, action) => {
            return applyStateupdate(state, action);
        },
        nudgeName: (state, action) => {
            return applyStateupdate(state, action);
        },
        nudgeNameDuplicateError: (state, action) => {
            return applyStateupdate(state, action);
        },
        legalEntity: (state, action) => {
            return applyStateupdate(state, action);
        },
        userGroup: (state, action) => {
            return applyStateupdate(state, action);
        },
        channel: (state, action) => {
            return applyStateupdate(state, action);
        },
        configureMetaData: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return initState;
        },
        resetStatesOnKPIchange: (state) => {
            return {
                ...state,
                ...initState,
            };
        },
        updateNudgeConfigInfo: (state, action) => {
            const updatedInfo = action.payload;
            return {
                ...state,
                ...updatedInfo,
            };
        },
        userFilter: (state, action) => {
            return applyStateupdate(state, action);
        },
        requestedByName: (state, action) => {
            return applyStateupdate(state, action);
        },
        kpiList: (state, action) => {
            return applyStateupdate(state, action);
        },
    },
});

export default nudgeConfigureSlice;
