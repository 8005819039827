import type { CSSObject } from '@emotion/styled';
import { Box, Drawer, styled } from '@mui/material';
import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import type { Theme } from '@mui/material/styles';
import Image from 'next/image';

import { BrownGrayColor, CodGrayColor, white } from '@/src/styles/theme/lightThemeOptions';

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
    theme?: Theme;
    open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: `1px 0px 1px 1px ${CodGrayColor}`,
    background: white,
    borderRadius: 'unset',
    '& .MuiToolbar-root': {
        height: '62px',
        '& .admin-header-nav': {
            paddingTop: '48px',
        },
        '& .MuiIconButton-edgeStart': {
            position: 'absolute',
            bottom: theme.spacing(4),
            margin: 'auto',
            svg: {
                fontSize: theme.spacing(6),
            },
        },
    },
    transition: 'width margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    }),
    '&.homePageHeader': {
        background: 'transparent',
        boxShadow: 'none',
    },
}));

export const AdminBodyContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(5.75, 1, 6.75, 7.25),
    width: '100%',
    '&.main-section': {
        overflow: 'auto',
        height: 'calc(100dvh - 2.5em)',
        padding: '1rem',
    },
}));

export const openedMixin = (): CSSObject => ({
    width: drawerWidth,
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    overflowX: 'hidden',
    width: theme.spacing(14),
    [theme.breakpoints.up('sm')]: {
        width: theme.spacing(14),
    },
});

interface AdminDrawerProps {
    open: boolean;
    isTabScreen: string;
    theme?: Theme;
}

const stngToBool = (str: string) => str === 'true';
export const AdminDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AdminDrawerProps>(({ theme, open, isTabScreen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: stngToBool(isTabScreen || '') ? '99999' : '1',
    display: 'block',
    position: 'relative',
    '& .MuiList-root': {
        paddingTop: theme.spacing(0),
    },
    '& .MuiListItem-root': {
        borderBottom: `1px solid ${BrownGrayColor}`,
        '& .MuiButtonBase-root': {
            padding: theme.spacing(4.75),
        },
    },
    ...(open && {
        ...openedMixin(),
        '& .MuiDrawer-paper': openedMixin(),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    ...(stngToBool(isTabScreen || '') && {
        '& .MuiDrawer-paper': {
            width: '23% !important',
            borderRadius: '0',
        },
    }),
}));
export const DrawerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    minHeight: theme.spacing(16),
}));
export const AdminOnlyStyled = styled(Image)({
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: 'auto',
    zIndex: '-1',
});
