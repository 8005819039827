import type { GetStaticProps } from 'next';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import {
    StyledErrorBox,
    StyledTypography,
} from '../modules/ErrorPagesStyles/ErrorPagesStyle.style';
import { getBlobUrl } from '../utils/commonUtil';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common', 'error'])),
        },
    };
};

const ForbiddenPage = () => {
    const { t } = useTranslation(['error']);
    const [hydrated, setHydrated] = React.useState(false);
    React.useEffect(() => {
        setHydrated(true);
    }, []);
    if (!hydrated) {
        // Returns null on first render, so the client and server match
        return null;
    }
    return (
        <>
            <StyledErrorBox>
                <Image src={getBlobUrl('403')} alt="Image" width={80} height={20} />
                <StyledTypography variant="body2"> {t('error.403.message')}</StyledTypography>
                <StyledTypography
                    variant="body2"
                    sx={{
                        marginTop: '10px',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t('error.redirect', {
                            interpolation: { escapeValue: false },
                        }),
                    }}
                ></StyledTypography>
            </StyledErrorBox>
        </>
    );
};

ForbiddenPage.layout = '403';
export default ForbiddenPage;
