import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { kbSlice } from './apps/knowledge-base';
import { syncScheduleSlice } from './apps/knowledge-base/syncSchedule';
import { kpiSlice } from './apps/kpi/kpi';
import { createNudgeSlice, nudgePublishSlice, nudgeTriggerSlice } from './apps/nudge';
import { nudgeConfigureSlice } from './apps/nudge/configure';
import { nudgeDesignSlice } from './apps/nudge/deisgn';
import { nudgeScheduleSlice } from './apps/nudge/schedule';
import { reportsSectionSlice } from './apps/reports';
import { roleManagementSlice } from './apps/roleManagement';
import selectedMenu from './apps/selectedMenu';
import user from './apps/user';

const rootReducer: any = {
    user: user.reducer,
    createNudge: createNudgeSlice.reducer,
    nudgeDesign: nudgeDesignSlice.reducer,
    nudgeConfigure: nudgeConfigureSlice.reducer,
    nudgeSchedule: nudgeScheduleSlice.reducer,
    nudgeTrigger: nudgeTriggerSlice.reducer,
    nudgePublish: nudgePublishSlice.reducer,
    roleManagement: roleManagementSlice.reducer,
    selectedMenu: selectedMenu.reducer,
    reports: reportsSectionSlice.reducer,
    kb: kbSlice.reducer,
    kpi: kpiSlice.reducer,
    synchFolderSchedule: syncScheduleSlice.reducer,
};

const appStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: process.env.NEXT_PUBLIC_ENV !== 'PRODUCTION',
});

export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export default appStore;
