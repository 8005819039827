import styled from '@emotion/styled';
import { Box } from '@mui/system';
import Image from 'next/image';

import { getBlobUrl } from '@/src/utils/commonUtil';

const ChatBoxContainer = styled(Box)(() => ({
    backgroundColor: 'transparent',
    width: '100%',
    position: 'fixed',
    bottom: '15px',
    right: '10px',
    textAlign: 'right',
    padding: '0 15px',
    img: {
        width: '3rem',
    },
    '@media(min-width: 640px)': {
        width: '90%',
    },
}));
type ChatBoxProps = {
    onClick: React.MouseEventHandler<HTMLDivElement>;
};

const ChatBox: React.FC<ChatBoxProps> = ({ onClick }) => {
    const chatIconImageUrl = getBlobUrl('chatIcon');
    return (
        <ChatBoxContainer onClick={onClick} data-testid="chat-box-container">
            <Image src={chatIconImageUrl} alt="responsive chat icon" width={68} height={68} />
        </ChatBoxContainer>
    );
};
export default ChatBox;
