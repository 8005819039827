import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import type { FC, ReactNode } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { mediumScreen } from '@/src/shared/constants/MediaQueryConstants';
import type { RootState } from '@/src/store';
import { getBlobUrl } from '@/src/utils/commonUtil';

import { Header } from '../../Header';
import { AdminBodyContent, AdminOnlyStyled, AppBar } from './adminLayout.style';
import Sidebar from './components/Sidebar';

const AdminLayout: FC<{ children: ReactNode; isAccessRoutes: boolean }> = ({
    children,
    isAccessRoutes,
}) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [open, setOpen] = React.useState(false);
    const user = useSelector((state: RootState) => state.user['state/isAdminOnlyBgShow']);
    const theme = useTheme();

    const handleToggleSideNav = () => {
        setOpen(!open);
    };

    const isTabScreen: boolean = !useMediaQuery(mediumScreen);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} data-testid="admin-layout">
            <AppBar position="static" theme={theme}>
                <Toolbar>
                    <Header isadmin={true} open={open} handleToggleSideNav={handleToggleSideNav} />
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', position: 'relative' }}>
                {open || isTabScreen ? (
                    <Sidebar open={open} handleToggleSideNav={handleToggleSideNav} />
                ) : null}
                {user && (
                    <AdminOnlyStyled
                        src={getBlobUrl('powerUsersBg')}
                        alt=" no power user"
                        width={80}
                        height={20}
                    />
                )}
                <AdminBodyContent
                    component="main"
                    className="main-section"
                    data-testid="Admin-Body-Content"
                >
                    {isAccessRoutes && <Box>{children}</Box>}
                </AdminBodyContent>
            </Box>
        </Box>
    );
};
export default AdminLayout;
