export const createNudgeSteps = [
    'nudge.create.stepper.design',
    'nudge.create.stepper.configure',
    'nudge.create.stepper.schedule',
];
export const createEventNudgeSteps = [
    'nudge.create.stepper.design',
    'nudge.create.stepper.configure',
    'nudge.create.stepper.trigger',
];
export type ActionType = {
    value: string;
    label: string;
    icon: string;
    actionEvent?: () => {};
    isEnabled?: boolean;
    href?: string;
    name?: string;
};

export const nudgeScheduleTypes = {
    sendNow: 'send now',
};

interface Actions {
    [key: string]: ActionType;
}

interface NudgeStatusType {
    [key: string]: ActionType[];
}

const actions: Actions = {
    edit: { value: 'edit', label: 'action.edit', icon: 'edit' },
    copy: { value: 'copy', label: 'action.copy', icon: 'copy', isEnabled: false },
    delete: { value: 'delete', label: 'action.delete', icon: 'delete', isEnabled: false },
    preview: { value: 'preview', label: 'action.preview', icon: 'preview' },
    pause: { value: 'pause', label: 'action.pause', icon: 'pause' },
    activate: { value: 'activate', label: 'action.activate', icon: 'activate' },
    reschedule: {
        value: 'reschedule',
        label: 'action.reschedule',
        href: '/reschedule',
        icon: 'reschedule',
    },
    runHistory: {
        value: 'runHistory',
        label: 'action.runHistory',
        href: '/nudge-run-history',
        icon: 'runHistory',
    },
};

export const NudgeStatusActions: NudgeStatusType = {
    unpublished: [actions.edit, actions.copy, actions.delete],
    published_paused: [
        actions.preview,
        actions.activate,
        actions.reschedule,
        actions.copy,
        actions.runHistory,
        actions.delete,
    ],
    published_active: [
        actions.preview,
        actions.pause,
        actions.reschedule,
        actions.copy,
        actions.runHistory,
        actions.delete,
    ],
    Archived: [
        actions.preview,
        actions.reschedule,
        actions.copy,
        actions.runHistory,
        actions.delete,
    ],
    KpiAction: [actions.activate, actions.edit, actions.delete],
};

export const YES = 'Yes';
export const RESCHEDULE = 'reschedule';
export const COMMON_NUDGE_STATUS = {
    UNPUBLISHED: 'unpublished',
    PUBLISHED: 'published',
    ACTIVE: 'Active',
};

export const COMMON_NUDGE_STATUS_DE = {
    ACTIVE: 'Aktiv',
};

export const RECURRENCE_RANGE = {
    END_DATE: 'end date',
    OCCURRENCE: 'occurence',
    NO_END_DATE: 'no end date',
};

export const SEND_LATER = 'send later';
export const SEND_NOW = 'send now';
export const RUN_ONCE_SCHEDULE = 'RunOnceSchedule';
export const EDIT_NUDGE = 'Edit Nudge';

export const LIMIT_INPUT_TAGS = 1;
export const LIMIT_INPUT_TAGS_MAX = 2;

export const DEFAULT_ALL_OPTION = { id: 0, name: 'All Legal Entities' };
export const editorMaxLength: number = 1000;
export const NUDGE_DESIGN_NAME_MAX_LENGTH = 80;
export const NUDGE_CONFIGURE_TITLE_MAX_LENGTH = 80;
