/* eslint-disable unused-imports/no-unused-vars */
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import dynamic from 'next/dynamic';

import { mediumScreen } from '@/src/shared/constants/MediaQueryConstants';

import { AppHeaderContainer } from '../layouts/style';
import { Logo } from './Logo';

const ClientUserMenu = dynamic(() => import('./UserMenu'), {
    ssr: false,
});

export const Header = ({ isadmin = false, open = false, handleToggleSideNav }: any) => {
    const isTabScreen: boolean = useMediaQuery(mediumScreen);

    return (
        <AppHeaderContainer isadmin={isadmin.toString()} data-testid="ginger_header">
            <Box style={{ display: 'flex' }}>
                {isTabScreen && isadmin ? (
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        style={{ display: 'hidden' }}
                        onClick={handleToggleSideNav}
                    >
                        <MenuIcon />
                    </IconButton>
                ) : null}
                <Logo />
            </Box>
            <ClientUserMenu isadmin={isadmin} />
        </AppHeaderContainer>
    );
};
