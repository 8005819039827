/* eslint-disable no-param-reassign */
import type { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import Router from 'next/router';

import GetMSALResponse from '@/src/utils/msalResponseUtil';

import { APP_BASE_API_URL } from './endpoints/apiUrls';

export const appApiInstance: AxiosInstance = axios.create({
    baseURL: APP_BASE_API_URL,
    validateStatus: (status) => status <= 500,
});

export const apiInstance = axios.create();

const authInterceptor = async (config: InternalAxiosRequestConfig) => {
    try {
        const tokens = await GetMSALResponse();
        const { loginRequestToken, customRequestToken } = tokens;
        if (!config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${loginRequestToken.accessToken}`;
        }

        if (!config.headers.Authentication) {
            config.headers.Authentication = `${customRequestToken.accessToken}`;
        }

        return config;
    } catch (error) {
        throw new Error('Token acquisition error', { cause: error });
    }
};

const responseInterceptor = (response: AxiosResponse) => {
    let data;
    switch (response.status) {
        case 200:
            data = response?.data;
            if (!data) {
                throw new Error('API Error. No data!', { cause: response });
            }
            if (response.config.method === 'get') {
                // Modify response for GET request
            } else if (response.config.method === 'post') {
                // Modify response for POST request
                response.data = {
                    ...response.data,

                    modified: true,
                };
            }
            break;
        case 401:
            Router.push('/401');
            break;
        case 403:
            Router.push('/403');
            break;
        case 404:
            Router.push('/404');
            break;
        default:
            throw new Error('API Error! Invalid status code!', { cause: response });
    }

    return response;
};

appApiInstance.interceptors.request.use(authInterceptor);
appApiInstance.interceptors.response.use(responseInterceptor);

apiInstance.interceptors.request.use(authInterceptor);
apiInstance.interceptors.response.use(responseInterceptor);

export const httpClient = appApiInstance;
