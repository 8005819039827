/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
    // https://www.i18next.com/overview/configuration-options#logging
    debug: process.env.NEXT_PUBLIC_ENV === 'DEV',
    i18n: {
        defaultLocale: process.env.NEXT_PUBLIC_LOCALE || 'en',
        locales: ['fr', 'en', 'de'],
        localeDetection: false,
    },

    /** To avoid issues when deploying to some paas (vercel...) */
    localePath:
        // eslint-disable-next-line global-require
        require('path').resolve('./public/locales'),

    reloadOnPrerender: process.env.NEXT_PUBLIC_ENV === 'DEV',

    /**
     * @link https://github.com/i18next/next-i18next#6-advanced-configuration
     */
    // saveMissing: false,
    // strictMode: true,
    // serializeConfig: false,
    // react: { useSuspense: false }
};
