// CustomScrollbars.tsx
import type { FC } from 'react';
import React, { useState } from 'react';
import type { ScrollbarProps } from 'react-custom-scrollbars-2';
import Scrollbars from 'react-custom-scrollbars-2';

import { opaquePrimaryOrange, primaryOrange } from '../styles/theme/lightThemeOptions';

interface CustomScrollbarProps extends ScrollbarProps {
    styleName?: string;
}
interface RenderThumbProps {
    style: React.CSSProperties;
}
const CustomScrollbars: FC<CustomScrollbarProps> = ({ children, ...props }) => {
    const [scrolling, setScrolling] = useState(false);
    const [hoveringThumb, setHoveringThumb] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const renderThumb = ({ style, ...props }: RenderThumbProps) => {
        const thumbStyle = {
            borderRadius: 6,
            backgroundColor: scrolling || hoveringThumb ? primaryOrange : opaquePrimaryOrange,
            transition: 'background-color 0.5s ease',
        };
        return (
            <div
                data-testid="render-Thumb-div"
                style={{ ...style, ...thumbStyle }}
                onMouseEnter={() => setScrolling(true)}
                onMouseLeave={() => setScrolling(false)}
                onMouseOver={() => setHoveringThumb(true)}
                onMouseOut={() => setHoveringThumb(false)}
                {...props}
            />
        );
    };

    const handleScroll = (timeOutValue: number) => {
        setScrolling(true);
        const scrollingTimeout = setTimeout(() => {
            setScrolling(false);
        }, timeOutValue);
        if (scrolling) {
            clearTimeout(scrollingTimeout);
        }
    };

    return (
        <Scrollbars
            renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={200}
            className={`custom-scroll-bar ${props.styleName}`}
            onScroll={() => handleScroll(900)}
            onMouseOver={() => handleScroll(1)}
            universal
            {...props}
        >
            {children}
        </Scrollbars>
    );
};

export default CustomScrollbars;
