import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import type {
    HeadCell,
    NudgeHistoryHeadCell,
    SyncHistoryHeadCell,
} from '@/src/components/ListTable/type/ListTableType';

export const DEFAULT_TABLE_ORDER = 'desc';
export const DEFAULT_TABLE_ORDER_BY = 'Created_by_and_on';
export const DEFAULT_RUN_HISTORY_TABLE_ORDER_BY = 'Execution_Date_Time';
export const DEFAULT_SYNC_HISTORY_TABLE_ORDER_BY = 'Sync_Date_Time';
export const DEFAULT_TABLE_ROWS_PER_PAGE = 5;
export const ROW_PER_PAGE = 20;
export const DEFAULT_FLAG_IMAGE_URL =
    'https://gingerdevstorage.blob.core.windows.net/gingerbot/org/entityicons/';
export const actionDropdownoptions = ['Published', 'Unpublished', 'Archived'];
export const announceMentListTableHeadCells: readonly HeadCell[] = [
    {
        id: 'Nudge_Name',
        numeric: false,
        disablePadding: true,
        label: 'Nudge_Name',
    },
    {
        id: 'Mode',
        numeric: true,
        disablePadding: false,
        label: 'Mode',
    },
    {
        id: 'Frequency',
        numeric: true,
        disablePadding: false,
        label: 'Frequency',
    },
    {
        id: 'Legal_Entity',
        numeric: true,
        disablePadding: false,
        label: 'Legal_Entity',
    },
    {
        id: 'User_Group',
        numeric: true,
        disablePadding: false,
        label: 'User_Group',
    },
    {
        id: 'Next_Execution_Date',
        numeric: true,
        disablePadding: false,
        label: 'Next_Execution_Date',
    },
    {
        id: 'Last_Execution_Date',
        numeric: true,
        disablePadding: false,
        label: 'Last_Execution_Date',
    },
    {
        id: 'Created_by_and_on',
        numeric: true,
        disablePadding: true,
        label: 'Created_by_and_on',
    },
    {
        id: '',
        numeric: false,
        disablePadding: false,
        label: '',
    },
];

export const kpiRegistryListTableHeadCells: readonly HeadCell[] = [
    {
        id: 'kpis_Name',
        numeric: false,
        disablePadding: true,
        label: "KPI's",
    },
    {
        id: 'Trigger_Type',
        numeric: true,
        disablePadding: false,
        label: 'Trigger Type',
    },
    {
        id: 'description',
        numeric: true,
        disablePadding: false,
        label: 'Description',
    },
    {
        id: 'Created_by_and_on',
        numeric: true,
        disablePadding: true,
        label: 'Created_by_and_on',
    },
    {
        id: '',
        numeric: false,
        disablePadding: false,
        label: '',
    },
];

export const insightsListTableHeadCells: readonly HeadCell[] = [
    {
        id: 'Nudge_Name',
        numeric: false,
        disablePadding: true,
        label: 'Nudge_Name',
    },
    {
        id: 'Kpi',
        numeric: false,
        disablePadding: true,
        label: 'Kpi',
    },
    {
        id: 'Mode',
        numeric: true,
        disablePadding: false,
        label: 'Mode',
    },
    {
        id: 'Frequency',
        numeric: true,
        disablePadding: false,
        label: 'Frequency',
    },
    {
        id: 'Legal_Entity',
        numeric: true,
        disablePadding: false,
        label: 'Legal_Entity',
    },
    {
        id: 'User_Group',
        numeric: true,
        disablePadding: false,

        label: 'User_Group',
    },
    {
        id: 'Next_Execution_Date',
        numeric: true,
        disablePadding: false,
        label: 'Next_Execution_Date',
    },
    {
        id: 'Last_Execution_Date',
        numeric: true,
        disablePadding: false,
        label: 'Last_Execution_Date',
    },
    {
        id: 'Created_by_and_on',
        numeric: true,
        disablePadding: true,
        label: 'Created_by_and_on',
    },
    {
        id: '',
        numeric: false,
        disablePadding: false,
        label: '',
    },
];

export const nudgeHistoryTableHeadCells: readonly NudgeHistoryHeadCell[] = [
    {
        id: 'Schedule_Date_Time',
        numeric: false,
        disablePadding: true,
        label: 'Schedule_Date_Time',
    },
    {
        id: 'Execution_Date_Time',
        numeric: false,
        disablePadding: true,
        label: 'Execution_Date_Time',
    },
    {
        id: 'Trigger_Mode',
        numeric: true,
        disablePadding: false,
        label: 'Trigger_Mode',
    },
    {
        id: 'No_Of_Attempts',
        numeric: true,
        disablePadding: false,
        label: 'No_Of_Attempts',
    },
    {
        id: 'Status_Percentage',
        numeric: true,
        disablePadding: false,
        label: 'Status_Percentage',
    },
    {
        id: 'Recipients',
        numeric: true,
        disablePadding: false,

        label: 'Recipients',
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,

        label: '',
    },
];

export const syncHistoryTableHeadCells: readonly SyncHistoryHeadCell[] = [
    {
        id: 'Sync_Date_Time',
        numeric: false,
        disablePadding: true,
        label: 'Sync_Date_Time',
    },
    {
        id: 'Status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
    },
    {
        id: 'Remarks',
        numeric: true,
        disablePadding: false,
        label: 'Remarks',
    },
];

export const NO_OF_COUNT_SHOW_TABLE_TOOLTIP = 3;
export const NO_OF_COUNT_SHOW_TABLE_TOOLTIP_FOR_TAB = 2;
export const statusChipLabels = (
    all: number,
    published: number,
    unPublished: number,
    archived: number
) => [
    {
        title: 'nudgeList.status.chip.all',
        value: 'All',
        icon: <CheckCircleIcon />,
        status: 'primary',
        noOfStatus: all,
    },
    {
        title: 'nudgeList.status.chip.published',
        value: 'Published',
        icon: <CheckCircleIcon />,
        status: 'success',
        noOfStatus: published,
    },
    {
        title: 'nudgeList.status.chip.unpublished',
        value: 'Unpublished',
        icon: <CheckCircleIcon />,
        status: 'warning',
        noOfStatus: unPublished,
    },
    {
        title: 'nudgeList.status.chip.archived',
        value: 'Archived',
        icon: <CheckCircleIcon />,
        status: 'secondary',
        noOfStatus: archived,
    },
];

export const reportsStatusChip = (
    all: number,
    published: number,
    unPublished: number,
    archived: number
) => [
    {
        title: 'Intent recognizer',

        icon: <CheckCircleIcon />,
        status: 'intent-recognizer',
        noOfStatus: all,
    },
    {
        title: 'QnA',
        icon: <CheckCircleIcon />,
        status: 'qna',
        noOfStatus: published,
    },
    {
        title: 'LLM',
        icon: <CheckCircleIcon />,
        status: 'llm',
        noOfStatus: unPublished,
    },
    {
        title: 'Unresolved',
        icon: <CheckCircleIcon />,
        status: 'unresolved',
        noOfStatus: archived,
    },
];

export const kpiStatusChipLabels = (Active: number, inActive: number) => [
    {
        title: 'nudgeList.status.chip.active',
        icon: <CheckCircleIcon />,
        status: 'success',
        noOfStatus: Active,
        value: '',
    },
    {
        title: 'nudgeList.status.chip.inactive',
        icon: <CheckCircleIcon />,
        status: 'warning',
        noOfStatus: inActive,
        value: '',
    },
];

export const modeType = {
    active: 'Active',
    pause: 'Paused',
    complete: 'Complete',
};

export const kpiRegistryTriggerType = {
    scheduled: 'Scheduled',
    eventDriven: 'Event Driven',
};

export const ROW_PER_PAGES = [20, 40, 60, 80, 100];

export const chipStatus = {
    unpublished: 'unpublished',
    published: 'published',
    archived: 'archived',
    all: 'all',
};

export const nudgeType = {
    announcement: 'AnnouncementNudge',
    insights: 'InsightNudge',
    KpiRegistryList: 'KpiRegistryList',
};

export const filterType = {
    nudgeStatus: 'nudgeStatus',
};
