import { createSlice } from '@reduxjs/toolkit';

import { Days, FrequencyArr, weekArr } from '@/src/shared/constants/SceduleNudgeConstants';

import { applyStateupdate } from '../../utils';

interface NudgeScheduleState {
    'state/frequency': string;
    'state/selectedWeekDay': number | null;
    'state/monthly_weekDayNo': number;
    'state/monthly_weekDay': number;
    previousState: NudgeScheduleState;
    'state/isReset': boolean;
}

export const initState: NudgeScheduleState = {
    'state/frequency': FrequencyArr[0].value as string,
    'state/selectedWeekDay': 7,
    'state/monthly_weekDayNo': Days[0].value as number,
    'state/monthly_weekDay': weekArr[0].value as number,
    previousState: {} as NudgeScheduleState,
    'state/isReset': false,
};

export const syncScheduleSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        frequency: (state, action) => {
            return applyStateupdate(state, action);
        },
        selectedWeekDay: (state, action) => {
            return applyStateupdate(state, action);
        },
        monthly_weekDayNo: (state, action) => {
            return applyStateupdate(state, action);
        },
        monthly_weekDay: (state, action) => {
            return applyStateupdate(state, action);
        },
        prevState: (state) => {
            return state.previousState;
        },
        isReset: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return {
                ...initState,
                'state/frequency': 'never',
            };
        },
    },
});

export default syncScheduleSlice;
