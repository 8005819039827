export const adminLayout = 'admin';
export const userDashboardLayout = 'user';
export const defaultlayout = 'frontend';
export const adminRole = 'Admin';
export const powerUser = 'Power User';
export const userRole = 'user';
export const unAuthorised = '401';
export const forbiddenPage = '403';
export const sessionTimeOut = 'sessionTimeOut';
export const unknownDomain = 'unknownDomain';
export const forbiddenOnMobile = 'forbiddenOnMobile';
export const unCaughtConversionEndMsg = 'Uncaught Error: conversation ended';
export const HealthCheck = 'HealthCheck';
