export const MAX_FILE_SIZE = 10000000;
export const VALID_FILE_EXTENSIONS = ['pdf', 'doc', 'docx', 'ppt', 'pptx'];
export const VALID_FILE_TYPES = [
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
export const FILE_EXISTS_ERROR = 'file exists';
export const FILE_EXISTS_ERROR_KEY_DE = 'Datei existiert';
export const KNOWLEDGEBAE_CONTENT_PATH = '/knowledge-base/content';
export const KB_QNA = {
    import: 'import',
    export: 'export',
    delete: 'trash',
};

export const formFieldArray = [
    {
        name: 'ContentSourceName',
        displayName: 'Content Source Name',
        value: '',
        type: 'string',
        placeholder: 'Type unique content source name here...',
    },
    {
        name: 'Domain',
        displayName: 'Domain',
        value: '',
        type: 'string',
        placeholder: 'Type domain here...',
    },
    {
        name: 'TENANTID',
        displayName: 'TENANT ID',
        value: '',
        type: 'string',
        placeholder: 'Type tenant id here...',
    },
    {
        name: 'ClientId',
        displayName: 'Client Id',
        value: '',
        type: 'string',
        placeholder: 'Type client id here...',
    },
    {
        name: 'ClientSecret',
        displayName: 'Client Secret',
        value: '',
        type: 'string',
        placeholder: 'Type client secret here...',
    },
];

export const sourceType = {
    document: 'Ginger Document Hub',
    sharePoint: 'SharePoint',
};
