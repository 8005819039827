import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { type ComponentType, useState } from 'react';

import Loader from '@/src/components/Loader';

import { loginRequest } from '../config/authConfig';
import { adminLayout } from '../constants/layoutConstant';

const authRequest = {
    ...loginRequest,
};

const ErrorComponent = ({ error }: any) => {
    return <Typography variant="h6">An Error Occurred: {error.errorCode}</Typography>;
};

export const withMsalAuthentication = <T extends object>(WrappedComponent: ComponentType<T>) => {
    const MsalAuth = (props: T) => {
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const setLoadingState = (val: boolean) => {
            setIsLoading(val);
        };

        return (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={Loader}
            >
                {isLoading && <Loader />}
                <WrappedComponent {...props} setLoadingState={setLoadingState} />
            </MsalAuthenticationTemplate>
        );
    };

    MsalAuth.layout = adminLayout;
    return MsalAuth;
};
