/* eslint-disable no-nested-ternary */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, CircularProgress, Container, styled, Typography } from '@mui/material';
import axios from 'axios';
import type { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useEffect, useState } from 'react';

import { HealthCheck } from '@/src/shared/constants/layoutConstant';

export const MessageContainer = styled(Box)(() => ({
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    justifyContent: 'center',
}));
const HealthCheckPage = () => {
    const { t } = useTranslation('common');

    const [isHealthy, setIsHealthy] = useState<boolean | null>(null);

    useEffect(() => {
        const checkHealth = async () => {
            try {
                const response = await axios.get('/api/health-check');
                setIsHealthy(response.data.code === 200);
            } catch (error) {
                setIsHealthy(false);
            }
        };

        checkHealth();
    }, []);

    return (
        <Box
            sx={{
                width: '100dvw',
                height: '100dvh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Container maxWidth="sm" style={{ textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    {t('heathCheck.title')}
                </Typography>
                {isHealthy === null ? (
                    <CircularProgress size={32} />
                ) : isHealthy ? (
                    <MessageContainer
                        sx={{
                            color: 'primaryGreenColor',
                        }}
                    >
                        <CheckCircleIcon />
                        <Typography variant="body1">{t('heathCheck.pass.label')}</Typography>
                    </MessageContainer>
                ) : (
                    <MessageContainer
                        sx={{
                            color: 'alertRed',
                        }}
                    >
                        <CheckCircleIcon />
                        <Typography variant="body1">{t('heathCheck.fail.label')}</Typography>
                    </MessageContainer>
                )}
            </Container>
        </Box>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common', 'error'])),
        },
    };
};
HealthCheckPage.layout = HealthCheck;
export default HealthCheckPage;
