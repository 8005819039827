import { Skeleton } from '@mui/material';
import React from 'react';

// Define an interface for the props
interface SkeletonWrapperProps {
    width: string | number | undefined;
    height?: string | number | undefined;
    animation?: false | 'wave' | 'pulse' | undefined;
    variant?: 'rectangular' | 'text' | 'rounded' | 'circular' | undefined;
}

// Define the reusable wrapper component
const SkeletonWrapper = ({
    width,
    height = 10,
    animation = 'wave',
    variant = 'rectangular',
}: SkeletonWrapperProps) => {
    return (
        <Skeleton
            variant={variant}
            width={width}
            height={height}
            animation={animation}
            data-testid={variant}
        />
    );
};

export default SkeletonWrapper;
