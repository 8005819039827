import { styled } from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as React from 'react';

import {
    greyColor,
    labelColor,
    lightBlackColor,
    lightGrayBlueColor,
    rowBackgroundColor,
    white,
} from '@/src/styles/theme/lightThemeOptions';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: 'rgba(0, 0, 0, 0.87)',
        border: `1px solid ${lightGrayBlueColor}`,
        backgroundColor: white,
        boxShadow: '0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 4px 8.6px 11.8px rgba(0, 0, 0, 0.03)',
        borderRadius: theme.spacing(1.5),
        padding: 0,

        '& .MuiTooltip-arrow': {
            color: lightBlackColor,
        },
    },
    '& .root': {
        color: `${greyColor}`,
    },
    '& .list': {
        fontFamily: 'Equip_Regular',
        fontSize: theme.spacing(3),
        listStyleType: 'none',
        padding: '0',
        color: lightBlackColor,
        margin: 0,
    },
    '& .header': {
        backgroundColor: lightBlackColor,
        padding: theme.spacing(1.25),
        borderTopLeftRadius: theme.spacing(1.5),
        borderTopRightRadius: theme.spacing(1.5),
        fontWeight: 700,
    },
    '& .content': {
        padding: theme.spacing(2.5),
        img: {
            marginRight: theme.spacing(1.25),
        },
    },
    '& .kpi-header': {
        padding: theme.spacing(1.25),
        fontFamily: 'Equip_Regular',
        fontSize: theme.spacing(3.5),
    },
    '& .kpi-content': {
        padding: theme.spacing(2.5),
        textAlign: 'left',
        fontFamily: 'Equip_Regular',
        fontSize: theme.spacing(3.5),
        color: labelColor,
    },
    '& .tooltip-content': {
        textAlign: 'justify',
        fontFamily: 'Equip_Regular',
        fontSize: theme.spacing(3.5),
        color: labelColor,
    },

    '& .kpi_list_item': {
        '&:nth-of-type(even)': {
            backgroundColor: rowBackgroundColor,
        },
    },

    '& .MuiTable-root.placeholderTableContainer': {
        borderCollapse: 'collapse',
        borderSpacing: '0',
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            boxShadow: 'none',
            '& .MuiTableCell-root': {
                borderBottom: '0',
                color: greyColor,
                padding: '0 5px',
            },
            '& .MuiTableCell-head': {
                minWidth: theme.spacing(31),
            },
            '& .MuiTableRow-head ': {
                background: white,
            },
        },
        '& .MuiTableBody-root': {
            boxShadow: 'none',
            '& .MuiTableCell-root': {
                borderBottom: '0',
                color: labelColor,
                padding: '0 5px',
            },
        },
    },
}));

const CustomizedTooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
    return (
        <>
            <HtmlTooltip {...props}></HtmlTooltip>
        </>
    );
};
export default CustomizedTooltip;
