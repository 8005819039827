import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PieChartIcon from '@mui/icons-material/PieChart';
import SchoolIcon from '@mui/icons-material/School';
import SettingsIcon from '@mui/icons-material/Settings';
import type { FilterOptionsState, SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import MarkdownIt from 'markdown-it';
import type { NextRouter } from 'next/router';
import type { ReactElement } from 'react';

import {
    AnnouncementIcon,
    AvatarDesignerIcon,
    ContentIcon,
    InsightsIcon,
    KnowledgeBaseIcon,
    NudgeDesignerIcon,
    QNaIcon,
    RoleManagementIcon,
    SmallTalkDesignerIcon,
} from '../components/Icons/menuIconComponents';
import type { StatusObj } from '../modules/Nudge/NudgeActionItems/PauseActive';
import { FileExtensionConstants, QnALabels } from '../shared/constants/CommonConstants';
import type { ActionType } from '../shared/constants/CreateNudgeConstants';
import { DEFAULT_ALL_OPTION } from '../shared/constants/CreateNudgeConstants';
import { sourceType } from '../shared/constants/KnowledgeBaseConstants';
import { adminRole, powerUser } from '../shared/constants/layoutConstant';
import type { GlobalPlaceholderData } from '../shared/services/apiServices/responseModel/globalPlaceholder';
import type { NudgeListResponse } from '../shared/services/apiServices/responseModel/nudgeListType';
import type { LegalEntity } from '../shared/services/apiServices/responseModel/nudgesMetaData';
import type { PlaceholderData } from '../shared/services/apiServices/responseModel/selectedKPIResponseType';
import type {
    Category,
    Permission,
} from '../shared/services/apiServices/responseModel/userApiType';
import type { UsersType } from '../shared/services/apiServices/responseModel/usersListType';
// eslint-disable-next-line import/no-cycle
import { greyColor } from '../styles/theme/lightThemeOptions';

type IconType = string | undefined;
export interface OptionsType {
    [x: string]: string;
}
interface Options {
    iconUrl?: string;
    legalEntity?: string;
    id?: number;
    name?: string;
}

export function getIcon(type: IconType): ReactElement | null {
    switch (type) {
        case 'TRAIN':
            return <SchoolIcon />;
        case 'NUDGE_DESIGNER':
            return <NudgeDesignerIcon />;
        case 'SMALLTALK_DESIGNER':
            return <SmallTalkDesignerIcon />;
        case 'announcement':
            return <AnnouncementIcon />;
        case 'Insights':
            return <InsightsIcon />;
        case 'MANAGE':
            return <SettingsIcon />;
        case 'KNOWLEDGEBASE':
            return <KnowledgeBaseIcon />;
        case 'REPORT':
            return <PieChartIcon />;
        case 'Custom_Report':
            return <GradingOutlinedIcon />;

        case 'Experience_Reports':
            return <GradingOutlinedIcon />;
        case 'ROLE_MANAGEMENT':
            return <RoleManagementIcon />;
        case 'Audit_Trail':
            return <GradingOutlinedIcon />;
        case 'AVATAR_DESIGNER':
            return <AvatarDesignerIcon />;
        case 'DELETE':
            return <DeleteOutlinedIcon />;
        case 'EDIT':
            return <ModeEditOutlineOutlinedIcon />;
        case 'CALENDAR':
            return <CalendarMonthSharpIcon sx={{ color: greyColor }} />;
        case 'KNOWLEDGEBASE_QNA':
            return <QNaIcon />;
        case 'KNOWLEDGEBASE_CONTENT':
            return <ContentIcon />;
        default:
            return null;
    }
}
export const downloadDocFromBlob = (blob: any, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
};
export function canAccessRoutes(permissionData: Permission[], basePath: string) {
    const pathArray = basePath?.split('/').slice(1);

    function checkNestedPermissions(categories: Category[], pathIndex: number): any {
        if (pathIndex === pathArray.length) {
            return true;
        }

        const currentCategory = categories.find(
            (cat) => cat.name.toLowerCase() === pathArray[pathIndex]?.toLowerCase()
        );

        if (currentCategory) {
            if (currentCategory.permissions && currentCategory.permissions.length) {
                return checkNestedPermissions(currentCategory.permissions || [], pathIndex + 1);
            }
            return true;
        }

        return false;
    }

    const matchingCategory = permissionData
        ?.flatMap((category) => category.categories)
        .find((cat) => cat.name.toLowerCase() === pathArray?.[0]?.toLowerCase());

    if (matchingCategory) {
        return checkNestedPermissions(matchingCategory.permissions || [], 1);
    }

    if (
        (!matchingCategory && basePath === '/not-access-mobile') ||
        (!matchingCategory && basePath === '/session-timeout') ||
        (!matchingCategory && basePath === '/docs/download')
    ) {
        return true;
    }

    return false;
}

export function replacePlaceHolders(
    string: string | undefined,
    placeholders: PlaceholderData[] | undefined
): any {
    let replacedString = string;
    placeholders?.forEach((placeholder) => {
        const { variable, displayName } = placeholder;
        replacedString = replacedString?.replaceAll(
            variable,
            `<span class="placeholder-color">${`&lt;${displayName}&gt;`}</span>`
        );
    });

    return replacedString;
}
export function convertPlaceholderToVar(
    nudgeMessage: string,
    placeholders: PlaceholderData[]
): string {
    let replacedString = nudgeMessage;
    placeholders.forEach((placeholder) => {
        const { variable, displayName } = placeholder;
        replacedString = replacedString.replaceAll(
            new RegExp(`&lt;${displayName}&gt;`, 'g'),
            ` ${variable} `
        );
    });

    return replacedString;
}

export const preProcessMarkdown = (input: any) => {
    const getStrongInput = input.replaceAll(/B&B(.*?)B&B/g, '<strong>$1</strong>');
    const getItalicsInput = getStrongInput.replaceAll(/!!(.*?)!!/g, '<em>$1</em>');
    return getItalicsInput.replaceAll(/@@(.*?)@@/g, '<u>$1</u>');
};
export const isQuestionDetailKeyPresent = (value: string) => {
    const labels = QnALabels;
    return labels.includes(value);
};
export const convertMarkdownToHtml = (markdown: string, currentUserName?: string) => {
    if (markdown.includes('$context.User.UserName')) {
        markdown = markdown.replace('$context.User.UserName', currentUserName || '');
    }

    markdown = markdown.replaceAll('\n\n ', '<p><br/></p>');
    const converter = new MarkdownIt({ html: true, breaks: true });
    const preprocessedMarkdown = preProcessMarkdown(markdown);
    const html = converter.render(preprocessedMarkdown);
    return html;
};
export const renderWithLineBreaks = (text: string) => {
    return text?.split('\n').flatMap((line: any, index: any) => [line, <br key={index} />]);
};
export const convertUserHandlerToName = (markdown: string, currentUserName: string) => {
    if (markdown.includes('$context.User.UserName')) {
        markdown = markdown.replace('$context.User.UserName', currentUserName || '');
    }
    return markdown;
};

export const clickAction = (
    nudgeData: NudgeListResponse,
    action: ActionType,
    router: NextRouter,
    setNudgeId: React.Dispatch<React.SetStateAction<string | null>>,
    setModalObj: React.Dispatch<StatusObj>
) => {
    const parentRoute = router?.pathname || '/';
    switch (action.value.toLowerCase()) {
        case 'preview':
            setNudgeId(nudgeData.id);
            break;
        case 'pause':
            setModalObj({ mode: 'Paused', id: nudgeData.id, label: action.label });
            break;
        case 'activate':
            setModalObj({ mode: 'Active', id: nudgeData.id, label: action.label });
            break;
        case 'runhistory':
            router.push(`${parentRoute}${action?.href}/${nudgeData.id}` || '#');
            break;
        case 'reschedule':
            router.push(`${parentRoute}${action?.href}/${nudgeData.id}` || '#');
            break;
        default:
            break;
    }
};

export const checkUserRoleBgShowEligibility = (usersList: Array<UsersType>) => {
    const adminUserList =
        usersList?.find((users: UsersType) => users.type === adminRole)?.users || [];

    const powerUserLists =
        usersList?.find((users: UsersType) => users.type === powerUser)?.users || [];

    return adminUserList.length === 1 && !powerUserLists.length;
};

export const filterSearchOptions = (
    options: any[],
    state: FilterOptionsState<unknown>,
    propertyName: string[]
) => {
    const displayOptions = (options || []).filter((option: any) => {
        const inputValue = state.inputValue.toLowerCase().trim();
        return propertyName.some((prop: string) => {
            if (prop in option) {
                return option[prop].toLowerCase().trim().includes(inputValue);
            }
            return false;
        });
    });
    return displayOptions;
};

export const getUpdatedLegalEntities = (legalEntities: Array<Options | LegalEntity>) => {
    return legalEntities.includes(DEFAULT_ALL_OPTION)
        ? legalEntities.filter((entity: Options | LegalEntity) => entity !== DEFAULT_ALL_OPTION)
        : legalEntities;
};

export const createMUISvgIcon = (svgContent: JSX.Element) => {
    const MUISvgIcon: React.FC<SvgIconProps> = ({ ...props }) => {
        return <SvgIcon {...props}>{svgContent}</SvgIcon>;
    };
    return MUISvgIcon;
};
export const createMarkdownTable = (tableData: Array<Array<string>>): string => {
    // Construct Markdown table
    if (tableData.length > 0) {
        const headerRow = tableData[0];
        const markdownHeader = `| ${headerRow.join(' | ')} |`;
        const markdownDivider = `| ${Array(headerRow.length).fill('---').join(' | ')} |`;
        const markdownRows = tableData
            .slice(1)
            .map((row) => `| ${row.join(' | ')} |`)
            .join('\n');
        const markdownTable = `${markdownHeader}\n${markdownDivider}\n${markdownRows}\n`;
        return markdownTable;
    }
    return '';
};

export const findDisplayName = (
    variableToFind: string,
    arrayOfObjects: Array<GlobalPlaceholderData>
): string | null => {
    // eslint-disable-next-line no-restricted-syntax
    for (const obj of arrayOfObjects) {
        if (obj.variable === variableToFind) {
            return obj.displayName;
        }
    }
    return null;
};
/**
 * @doc Function
 * @name Component/Function getBlobUrl
 * @description [this function returns the blob url of icons by passing icon's name only]
 * @param {string} fileName - [name of icon]
 * @param {string} fileExtension - [name of file Extension]
 * @returns {string} [returns blob url]
 */
export const getBlobUrl = (
    fileName: string,
    fileExtension: string = FileExtensionConstants.svg,
    containerPathName: string = `gingerbot/org/icons`
) => {
    const storageAccountName = process.env.NEXT_PUBLIC_STORAGE_ACCOUNT_NAME;
    const containerName = containerPathName;
    const imageUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}.${fileExtension}`;
    return imageUrl;
};
export const extractErrorValue = (errors: any) => {
    let errorValue = '';
    errors.forEach((error: any) => {
        // eslint-disable-next-line unused-imports/no-unused-vars
        Object.entries(error).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                errorValue += `${value.join(', ')}`;
            }
        });
    });

    return errorValue.trim(); // Remove trailing whitespace
};
export const calculateDateRange = (selectedValue: string) => {
    const today = new Date();
    const startDate = new Date(today);
    const endDate = new Date(today);

    switch (selectedValue) {
        case '7':
            startDate.setDate(today.getDate() - 6);
            break;
        case '30':
            startDate.setDate(today.getDate() - 29);
            break;
        case '90':
            startDate.setMonth(today.getMonth() - 2);
            break;
        case '365':
            startDate.setFullYear(today.getFullYear() - 1);
            break;
        default:
            break;
    }

    return { startDate, endDate };
};
export const getIsoDateWithoutTimezone = (date: any) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};
export const checkSourceType = (url: string | undefined) => {
    switch (url) {
        case 'ginger-document-hub':
            return sourceType.document;
        case 'share-point':
            return sourceType.sharePoint;
        default:
            return sourceType.document;
    }
};

export const getContentSourceTranslations = (url: string) => {
    const translationMapping: Record<string, Record<string, string>> = {
        'Ginger Document Hub': {
            title: 'kb.no-folders.title',
            subTitle: 'kb.no-folders.subtitle',
            buttonLabel: 'kb.create.folder',
        },
        'Share Point': {
            title: 'kb.no-k-repos.title',
            subTitle: 'kb.no-k-repos.subtitle',
            buttonLabel: 'kb.create',
        },
    };

    return (
        translationMapping[url] || {
            title: '',
            subTitle: '',
            buttonLabel: '',
        }
    );
};

export function areAllValuesEmpty(array: any, variableName: string) {
    return array.every((item: any) => item[variableName] === 0);
}
export interface GetSynchScheduleType {
    'state/frequency': string;
    'state/selectedWeekDay': number | null;
    'state/monthly_weekDayNo': number;
    'state/monthly_weekDay': number;
}

export interface GetSynchScheduleResponseType {
    data: {
        syncFrequency: string;
        syncKey: string | null;
    };
}
// Extract filename from Content-Disposition header
export const fileNameFromHeaders = (headers: { [x: string]: any }) => {
    const disposition = headers['content-disposition'];
    let fileName = 'data.csv';
    if (disposition && disposition.includes('filename=')) {
        const match = disposition.match(/filename="?(.+?)"?(\s|$)/);
        if (match && match[1]) {
            fileName = match[1].replace(/[";]/g, '');
        }
    }
    return fileName;
};
export const getSyncScheduleTransformResponse = (
    response: GetSynchScheduleType
): GetSynchScheduleResponseType => {
    let syncFrequency: string = 'never';
    let syncKey: string | null = null;

    if (response['state/frequency'] === 'Daily') {
        syncFrequency = 'Daily';
    } else if (response['state/frequency'] === 'Weekly') {
        syncFrequency = 'Weekly';
        syncKey =
            response['state/selectedWeekDay'] !== null
                ? response['state/selectedWeekDay'].toString()
                : null;
    } else if (response['state/frequency'] === 'Monthly') {
        syncFrequency = 'Monthly';
        if (
            response['state/monthly_weekDayNo'] !== null &&
            response['state/monthly_weekDay'] !== null
        ) {
            syncKey = `${response['state/monthly_weekDay']}-${
                response['state/monthly_weekDayNo'] === 5
                    ? 'l'
                    : response['state/monthly_weekDayNo']
            }`.toString();
        }
    }

    const transformedData: GetSynchScheduleResponseType = {
        data: {
            syncFrequency: syncFrequency?.toLowerCase(),
            syncKey,
        },
    };

    return transformedData;
};

export const exportDocsFromBlob = (fileData: any, fileType: string, fileName: string) => {
    const contentType = `application/${fileType}`;
    const byteArray = Uint8Array.from(Buffer.from(fileData, 'base64'));
    const blob = new Blob([byteArray], { type: contentType });
    downloadDocFromBlob(blob, `${fileName}.${fileType}`);
};
export const extractFileName = (url: string): string | undefined => {
    const fileNameWithExtension = url.split('/').pop();
    if (!fileNameWithExtension) return undefined;

    const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
    return fileName;
};
/**
 * @doc Function
 * @name Function areArraysEqual
 * @description [Compare two arrays weather equal or not]
 * @param {any} arr1 - [first array]
 * @param {any} arr2 - [second array]
 * @returns {Type} [returns true if equal else false]
 */
export const areArraysEqual = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;

    return arr1.every((obj1) => arr2.some((obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)));
};
