import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

import { darkGrey, white } from '@/src/styles/theme/lightThemeOptions';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '12px',
        minWidth: '350px',
    },
    '& .MuiDialogContent-root': {
        padding: 0,
        background: white,
        borderRadius: '12px',
        '& .textContainer': {
            fontFamily: 'Equip_Regular',
            padding: theme.spacing(0, 3),
            '& .MuiTypography-root': {
                color: darkGrey,
                padding: theme.spacing(4, 0),
                display: 'flex',
                fontSize: theme.spacing(3.5),
                alignItems: 'flex-start',
                '& .MuiListItem-root': {
                    '& .MuiTypography-root': {
                        fontSize: theme.spacing(3.5),
                    },
                },
                '& .alertIcon': {
                    width: '21px',
                    height: '18px',
                },
            },
            '& .buttonsWrapper': {
                justifyContent: 'end',
                display: 'flex',
                padding: theme.spacing(4, 0),
                gap: '13px',
            },
        },
        '& .MuiDialogTitle-root': {
            color: white,
        },
    },
    '& .closeIcon': {
        position: 'relative',
        cursor: 'pointer',
        color: white,
    },
}));
