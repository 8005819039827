import { createSlice } from '@reduxjs/toolkit';

import { PREV_SELECTED_FOLDER } from '@/src/modules/knowledge-base/ContentSource/constants';
import type {
    Folder,
    LegalEntity,
} from '@/src/shared/services/apiServices/responseModel/kbFolderListType';

import { applyStateupdate } from '../utils';

interface KbSlice {
    'state/initialLoad': boolean;
    'state/loading': boolean;
    'state/dirty': boolean;
    'state/folderNames': string[];
    'state/folderList': Folder[];
    'state/selectedFolder': string | null;
    'state/isFolderDeletable': boolean;
    // new file upload
    'state/global': boolean;
    'state/legalEntity': LegalEntity[];
    'state/preselectFirstFolder': boolean | null;
    'state/sourceType': string;
    'state/selectedSharepointFiles': any[];
    'state/selectedFolderId': string;
    'state/siteId': string;
    'state/refreshFolderList': boolean;
    'state/showSyncNotification': boolean;
    'state/uploadingFile': boolean;
    'state/hasNextLink': boolean;
    'state/nextLink': any;
}

export const initState: KbSlice = {
    'state/initialLoad': true,
    'state/loading': false,
    'state/dirty': false,
    'state/folderNames': [],
    'state/folderList': [],
    'state/selectedFolder': null,
    'state/isFolderDeletable': false,
    'state/global': true,
    'state/legalEntity': [],
    'state/preselectFirstFolder': false,
    'state/sourceType': '',
    'state/selectedSharepointFiles': [],
    'state/selectedFolderId': '',
    'state/siteId': '',
    'state/refreshFolderList': false,
    'state/showSyncNotification': false,
    'state/uploadingFile': false,
    'state/hasNextLink': false,
    'state/nextLink': '',
};

export const kbSlice = createSlice({
    name: 'state',
    initialState: initState,

    reducers: {
        folderList: (state, action) => {
            const newState = applyStateupdate(state, action);
            const folders = newState['state/folderList'];
            const folderNames =
                folders?.length &&
                folders
                    .map((folder: Folder) => folder.name)
                    .sort((a: string, b: string) => a.localeCompare(b));
            newState['state/folderNames'] = folderNames;
            const selectedFolder = newState['state/selectedFolder'];
            if (!folders.find((folder: Folder) => folder.name === selectedFolder)) {
                newState['state/selectedFolder'] = null;
            }
            if (newState['state/preselectFirstFolder'] && folderNames.length) {
                const first = folderNames[0];
                newState['state/selectedFolder'] = first;
            }
            newState['state/preselectFirstFolder'] = false;
            return newState;
        },
        selectedFolder: (state, action) => {
            const newState = applyStateupdate(state, action);
            newState['state/global'] = true;
            newState['state/legalEntity'] = [];
            return newState;
        },
        selectFirstFolder: (state) => {
            const newState = state;
            const prevSelectedFolder = sessionStorage.getItem(PREV_SELECTED_FOLDER);
            const firstFolder = prevSelectedFolder || state['state/folderNames'][0];
            newState['state/selectedFolder'] = firstFolder || null;
            newState['state/global'] = true;
            newState['state/legalEntity'] = [];
            return newState;
        },
        preSelectFirstFolder: (state) => {
            const newState = state;
            newState['state/preselectFirstFolder'] = true;
            return newState;
        },
        dirty: (state, action) => {
            return applyStateupdate(state, action);
        },
        initialLoad: (state, action) => {
            return applyStateupdate(state, action);
        },
        loading: (state, action) => {
            return applyStateupdate(state, action);
        },
        global: (state, action) => {
            return applyStateupdate(state, action);
        },
        legalEntity: (state, action) => {
            return applyStateupdate(state, action);
        },
        sourceType: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return initState;
        },
        selectedSharepointFiles: (state, action) => {
            return applyStateupdate(state, action);
        },
        selectedFolderId: (state, action) => {
            return applyStateupdate(state, action);
        },
        isFolderDeletable: (state, action) => {
            return applyStateupdate(state, action);
        },
        siteId: (state, action) => {
            return applyStateupdate(state, action);
        },
        refreshFolderList: (state, action) => {
            return applyStateupdate(state, action);
        },
        showSyncNotification: (state, action) => {
            return applyStateupdate(state, action);
        },
        uploadingFile: (state, action) => {
            return applyStateupdate(state, action);
        },
        hasNextLink: (state, action) => {
            return applyStateupdate(state, action);
        },
        nextLink: (state, action) => {
            return applyStateupdate(state, action);
        },
    },
});

export default kbSlice;
