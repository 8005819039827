import './index.scss';
import '../components/Editor/EditorMentionStyle.scss';
import 'moment/min/locales';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import moment from 'moment';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, i18n } from 'next-i18next';
import type { FunctionComponent } from 'react';
import { Provider } from 'react-redux';

import * as i18nConfig from '../../next-i18next.config';
import ErrorBoundary from '../components/Error/ErrorBoundary';
import Layout from '../components/layouts/Layout';
import type { NextPageWithLayout } from '../components/layouts/types/adminLayoutTypes';
import createEmotionCache from '../createEmotionCache';
import { msalConfig } from '../shared/config/authConfig';
import { MemoAuthProvider } from '../shared/contexts/AzureAuthProvider';
import store from '../store';
import lightThemeOptions, { withTheme } from '../styles/theme/lightThemeOptions';
import { getBlobUrl } from '../utils/commonUtil';
import { CustomNavigationClient } from '../utils/NavigationClient';
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface AppPropsWithLayout extends AppProps {
    Component: NextPageWithLayout;
    emotionCache?: EmotionCache;
    clocale: string;
}
const lightTheme = withTheme(createTheme(lightThemeOptions));

const MyApp: FunctionComponent<AppPropsWithLayout> = (props) => {
    const router = useRouter();
    const pathname = router.asPath.split('?')[0];
    const msalInstance = new PublicClientApplication(msalConfig(pathname));
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigationClient = new CustomNavigationClient(router);
    msalInstance.setNavigationClient(navigationClient);
    const getLayout =
        Component.getLayout ??
        ((page) => <Layout layoutType={Component.layout || ''}>{page}</Layout>);

    const locale: string | undefined = process.env.NEXT_PUBLIC_LOCALE;

    moment.locale(locale);

    const locales: any = {
        de: () => import('dayjs/locale/de'),
        en: () => import('dayjs/locale/en'),
    };

    (() => {
        locales[locale || 'en']().then(() => dayjs.locale(locale || 'en'));
    })();

    return (
        <>
            <Head>
                <title>{i18n?.t('app_name')}</title>

                <meta property="og:title" content={i18n?.t('app_name')} key="title" />
                <meta property="version" content={process.env.NEXT_PUBLIC_APP_VERSION} />
                <meta name="theme-color" content="theme-color" />
                <link rel="shortcut icon" href={getBlobUrl('favicon')} />
                <meta name="google" content="notranslate"></meta>
                <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
                <meta
                    name="viewport"
                    content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi, viewport-fit=cover"
                />
                <base target="_blank"></base>
            </Head>
            <CacheProvider value={emotionCache}>
                <Provider store={store}>
                    <ThemeProvider theme={lightTheme}>
                        <ErrorBoundary>
                            <CssBaseline />
                            <MsalProvider instance={msalInstance}>
                                <MemoAuthProvider>
                                    {getLayout(<Component {...pageProps} />)}
                                </MemoAuthProvider>
                            </MsalProvider>
                        </ErrorBoundary>
                    </ThemeProvider>
                </Provider>
            </CacheProvider>
        </>
    );
};

export default appWithTranslation(MyApp, i18nConfig);
