import { SEND_LATER } from './CreateNudgeConstants';

export type Option = {
    id: number | string;
    label: string;
    value: string | number;
};

enum Frequency {
    DAILY = 'nudge.schedule.sendLater.frequency.daily',
    WEEKLY = 'nudge.schedule.sendLater.frequency.weekly',
    MONTHLY = 'nudge.schedule.sendLater.frequency.monthly',
    YEARLY = 'nudge.schedule.sendLater.frequency.yearly',
}

enum Counting {
    FIRST = 'nudge.schedule.sendLater.counting.first',
    SECOND = 'nudge.schedule.sendLater.counting.second',
    THIRD = 'nudge.schedule.sendLater.counting.third',
    FOURTH = 'nudge.schedule.sendLater.counting.fourth',
    LAST = 'nudge.schedule.sendLater.counting.last',
}

enum ScheduleType {
    NOW = 'nudge.schedule.sendLater.scheduleType.now',
    LATER = 'nudge.schedule.sendLater.scheduleType.later',
}

export const shortWeekArr: Option[] = [
    { id: 1, label: '', value: 7 },
    { id: 2, label: '', value: 1 },
    { id: 3, label: '', value: 2 },
    { id: 4, label: '', value: 3 },
    { id: 5, label: '', value: 4 },
    { id: 6, label: '', value: 5 },
    { id: 7, label: '', value: 6 },
];

export const weekArr: Option[] = [
    { id: 1, label: '', value: 7 },
    { id: 2, label: '', value: 1 },
    { id: 3, label: '', value: 2 },
    { id: 4, label: '', value: 3 },
    { id: 5, label: '', value: 4 },
    { id: 6, label: '', value: 5 },
    { id: 7, label: '', value: 6 },
];

export const FrequencyArr: Option[] = [
    { id: 1, label: Frequency.DAILY, value: 'Daily' },
    { id: 2, label: Frequency.WEEKLY, value: 'Weekly' },
    { id: 3, label: Frequency.MONTHLY, value: 'Monthly' },
    { id: 4, label: Frequency.YEARLY, value: 'Yearly' },
];

export const Days: Option[] = [
    { id: 1, label: Counting.FIRST, value: 1 },
    { id: 2, label: Counting.SECOND, value: 2 },
    { id: 3, label: Counting.THIRD, value: 3 },
    { id: 4, label: Counting.FOURTH, value: 4 },
    { id: 5, label: Counting.LAST, value: 5 },
];

export const scheduleTypes: Option[] = [
    { id: 1, label: ScheduleType.NOW, value: 'send now' },
    { id: 2, label: ScheduleType.LATER, value: SEND_LATER },
];
