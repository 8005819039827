import Image from 'next/image';
import Link from 'next/link';

import { LogoConstants } from '@/src/shared/constants/CommonConstants';
import { getBlobUrl } from '@/src/utils/commonUtil';

export const Logo = () => {
    const brandLogo = getBlobUrl(LogoConstants.logo);
    return (
        <>
            <Link href="/">
                <Image
                    src={brandLogo}
                    alt={LogoConstants.logo}
                    className="ginger-logo"
                    priority
                    width={122}
                    height={45}
                />
            </Link>
        </>
    );
};
