import { useTranslation } from 'next-i18next';

import { Counter, FeedsHeader, HeaderBox, HeaderTitle } from './style';

interface FeedsHeaderProps {
    feedsCount: number;
}

export const Header = ({ feedsCount }: FeedsHeaderProps) => {
    const { t } = useTranslation(['ginger']);
    return (
        <FeedsHeader data-testid="header">
            <HeaderBox>
                <HeaderTitle>{t('ginger.cares.header.heading')}</HeaderTitle>
                {feedsCount > 0 && (
                    <Counter fontFamily="Equip_Bold" data-testid="counter">
                        {feedsCount}
                    </Counter>
                )}
            </HeaderBox>
        </FeedsHeader>
    );
};
