import { createSlice } from '@reduxjs/toolkit';

import { applyStateupdate } from '../../utils';

interface NudgeTriggerState {
    'state/isPayloadModal': boolean;
}

export const initState: NudgeTriggerState = {
    'state/isPayloadModal': false,
};

export const nudgeTriggerSlice = createSlice({
    name: 'state',
    initialState: initState,
    reducers: {
        isPayloadModal: (state, action) => {
            return applyStateupdate(state, action);
        },
        resetState: () => {
            return initState;
        },
    },
});

export default nudgeTriggerSlice;
