import { FiberManualRecord } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { ReactNode } from 'react';
import { useState } from 'react';

import CancelDialog from '@/src/components/Modal/Cancel/CancelDialog';
import { primaryOrange } from '@/src/styles/theme/lightThemeOptions';

import { internalServerMsg } from '../constants/CommonConstants';

type CustomizedDialogsProps = {
    open: boolean;
    handleClose: () => void;
    title: string;
    dialogContent: React.ReactElement;
    buttons: {
        label: string;
        variant: 'contained' | 'text' | 'outlined' | undefined;
        handleClick: () => void;
    }[];
    isErrorIconShow: boolean;
};
type ButtonDialogProps = {
    label: string;
    variant: 'contained' | 'text' | 'outlined' | undefined;
    handleClick: () => void;
};

const useErrorModalHooks = () => {
    const { t } = useTranslation(['modal', 'common']);
    const router = useRouter();
    const [showDialog, setShowDialog] = useState(false);
    const [responseErrorContent, setResponseErrorContent] = useState<any>([]);
    const [customButtonLabel, setCustomButtonLabel] = useState('');

    const responseApiErrorContent: any =
        responseErrorContent?.error || responseErrorContent?.errors;
    const customDialogContent = () => {
        if (
            responseApiErrorContent &&
            responseApiErrorContent.length > 0 &&
            Array.isArray(responseApiErrorContent)
        ) {
            return (
                <Box>
                    {responseApiErrorContent.map((val, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemText
                                primary={Object.values(val)}
                                sx={{
                                    padding: '0',
                                }}
                            />
                        </ListItem>
                    ))}
                </Box>
            );
        }
        if (
            typeof responseApiErrorContent === 'object' &&
            !Array.isArray(responseApiErrorContent)
        ) {
            return (
                <Box>
                    {Object.entries(responseApiErrorContent).map(([key, val], index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                <FiberManualRecord
                                    sx={{
                                        height: '10px',
                                        width: '10px',
                                        color: primaryOrange,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                key={key}
                                primary={val as ReactNode}
                                sx={{
                                    padding: '0',
                                }}
                            />
                        </ListItem>
                    ))}
                </Box>
            );
        }
        return <></>;
    };

    const openDialog = () => {
        setShowDialog(true);
    };

    const closeDialog = () => {
        if (responseErrorContent?.code === internalServerMsg) {
            setShowDialog(false);
            router?.reload();
        } else {
            setShowDialog(false);
        }
    };
    const isInternalServerError = responseErrorContent?.code === internalServerMsg;

    const commonButton: ButtonDialogProps = {
        label:
            customButtonLabel ||
            (isInternalServerError
                ? t('modal.button.error.cancel', { ns: 'common' })
                : t('modal.errormodal.button.OK', { ns: 'modal' })),
        variant: 'contained',
        handleClick: () => setShowDialog(false),
    };

    const errorModalButtons: ButtonDialogProps[] = isInternalServerError
        ? [
              {
                  label: customButtonLabel || t('modal.errormodal.button.no', { ns: 'modal' }),
                  variant: 'outlined',
                  handleClick: () => setShowDialog(false),
              },
              {
                  label: customButtonLabel || t('modal.errormodal.button.yes', { ns: 'modal' }),
                  variant: 'contained',
                  handleClick: closeDialog,
              },
          ]
        : [commonButton];
    const dialogProps: CustomizedDialogsProps = {
        open: showDialog,
        handleClose: () => setShowDialog(false),
        title: t('modal.systemerrormodal.title', { ns: 'modal' }),
        dialogContent: customDialogContent(),
        buttons: errorModalButtons,
        isErrorIconShow: false,
    };

    return {
        openDialog,
        setResponseErrorContent,
        setCustomButtonLabel,
        dialogComponent: <CancelDialog {...dialogProps} />,
    };
};
export default useErrorModalHooks;
