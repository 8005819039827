import type { GetStaticProps } from 'next';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import {
    StyledErrorBox,
    StyledTypography,
} from '../modules/ErrorPagesStyles/ErrorPagesStyle.style';
import { AuthContext } from '../shared/contexts/AzureAuthProvider';
import { getBlobUrl } from '../utils/commonUtil';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common', 'error'])),
        },
    };
};

const UnauthorisedPage = () => {
    const { t } = useTranslation(['error']);
    const [hydrated, setHydrated] = React.useState(false);
    const { logout } = React.useContext(AuthContext);

    React.useEffect(() => {
        setHydrated(true);
    }, []);
    if (!hydrated) {
        // Returns null on first render, so the client and server match
        return null;
    }

    const handleLogout = (event: any) => {
        event.preventDefault();
        logout();
    };

    return (
        <>
            <StyledErrorBox>
                <Image src={getBlobUrl('401')} alt="Image" width={80} height={20} />
                <StyledTypography variant="body2">{t('error.401.message')}</StyledTypography>
                <StyledTypography
                    variant="body2"
                    sx={{
                        marginTop: '10px',
                    }}
                    onClick={(e) => {
                        handleLogout(e);
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t('error.401.redirect', {
                            interpolation: { escapeValue: false },
                        }),
                    }}
                    data-testid="logout_paragraph"
                ></StyledTypography>
            </StyledErrorBox>
        </>
    );
};

UnauthorisedPage.layout = '401';
export default UnauthorisedPage;
