import { Box, styled } from '@mui/system';

import {
    hoverPaleOrange,
    lightBlackColor,
    popperDividerColor,
    primaryOrange,
} from '@/src/styles/theme/lightThemeOptions';

export const FeedsHeader = styled(Box)({
    borderBottom: `1px solid ${popperDividerColor}`,
    padding: '23px 20px 23px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const HeaderBox = styled(Box)({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
});

export const HeaderTitle = styled(Box)(({ theme }) => ({
    fontFamily: 'Equip_Extended_Bold',
    fontSize: theme.spacing(4.5),
    color: lightBlackColor,
}));

export const Counter = styled(Box)(({ theme }) => ({
    height: theme.spacing(4.5),
    background: hoverPaleOrange,
    mixBlendMode: 'normal',
    borderRadius: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: theme.spacing(3),
    lineHeight: theme.spacing(4),
    color: primaryOrange,
    padding: theme.spacing(0, 2.5),
}));
